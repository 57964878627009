import React, { memo as Memo, useMemo } from 'react'
//*component
import { Icon } from 'components/common'
//* Styles
import ShareIconStyle from './style'

const ShareIcon = Memo((props)=>{
    return (
        <ShareIconStyle onClick={props.onClick} target={props.target ? '_blank' : '_self'} className={`socIconItem FlexBox alignMiddle textColor ${props.parentClass}`} rel='noopener noreferrer' href={props.url}>
            <Icon className={props.className} />
        </ShareIconStyle>
    )
})

export default ShareIcon;