import React, { memo as Memo } from 'react'

import BurgerStyle from './style'

const Burger = Memo((props) => {

    return (
        <BurgerStyle className='brg'>
            <div className={`Burger ${props.checked  ? "active" : ''}`} onClick={props.onClick}>
                <span id='line1'/>
                <span id='line2'/>
                <span id='line3'/>
                <span id='line4'/>
            </div>
        </BurgerStyle>
    )
})

export default Burger