import styled from 'styled-components'

const ButtonStyle = styled.div.attrs((props) => ({}))`
	--minWidth1: var(--sp58x);
	--minWidth2: var(--sp93x);
	--paddingTop: var(--sp2-8x);
	--paddingFrameTop: var(--sp4x);
	--paddingLeft: var(--sp7x);
	--lineWidth: var(--sp2x);
	--marginBottom: var(--sp9x);

	&.btn-cont{
		margin-top: var(--marginBottom);
	}

	.btn {
		-webkit-appearance: none;
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		color: var(--backColor);
		line-height: var(--lineHeightXS);
		position: relative;
		z-index: 1;
		border: 0;
		font-size: var(--pL);
		overflow: hidden;
		background-color: transparent;

		&.galCat {
			font-size: var(--pXS);
		}

		&.btnAnimation {
			padding: var(--paddingTop) var(--paddingLeft);
			min-width: var(--minWidth1);
			> * {
				pointer-events: none;
			}
			.LottieWrapp {
				position: absolute;
				top: 1px;
				bottom: -1px;
				z-index: -1;

				svg{
					width: auto !important;
				}
				&:first-child {
					left: 0;
					&:after {
						right: -2px;
						left: 88%;
						height: 100%;
						width: 500%;
					}
				}
				&:last-child {
					right: 0;
					transform: rotate(180deg);
					&:after {
						right: -2px;
						left: 88%;
						height: 100%;
						width: 500%;
					}
				}
				&:after {
					content: '';
					background-color: var(--textColor);
					position: absolute;
					top: 0;
					bottom: 0;
				}

				svg {
					path {
						fill: var(--textColor);
					}
				}
			}

			&.reverseColor {
                		color: var(--textColor);
				.LottieWrapp {
					svg {
						path {
							fill: var(--backColor);
						}
					}
					&:after {
						background-color: var(--backColor);
					}
				}
			}
		}
		&.btnFrame {
			color: var(--textColor);
			padding: var(--paddingFrameTop) var(--paddingLeft);
			min-width: var(--minWidth2);
			.LeftLine {
				pointer-events: none;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: var(--lineWidth);
				height: 100%;
				z-index: 4;
				clip-path: polygon(0 0, 100% var(--lineWidth), 100% 100%, 0% 100%);
			}
			.TopLine {
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 3;
				width: 100%;
				height: var(--lineWidth);
				transform: rotate(180deg);
				clip-path: polygon(var(--lineWidth) 0%, 100% 0%, 100% 100%, 0% 100%);
			}
			.RightLine {
				pointer-events: none;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: var(--lineWidth);
				height: 100%;
				transform: rotate(180deg);
				z-index: 5;
				clip-path: polygon(0 0, 100% var(--lineWidth), 100% 81%, 0 100%);
			}
			.BottomLine {
				pointer-events: none;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--lineWidth);
				z-index: 4;
				clip-path: polygon(var(--lineWidth) 0, 100% 0, 100% 100%, 0% 100%);
			}
		}

		&.filter-btn {
			color: var(--textColor);
			border: 1px solid var(--textColor);
			border-radius: var(--sp5x);
			padding: var(--sp1-5x) var(--sp3x);
			transition: var(--trTime);
			font-size: var(--pXS);
			&.active {
				color:#fff;
				background-color: var(--textColor);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--paddingTop: var(--sp3x);
		--paddingLeft: var(--sp5x);
	}
	
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--minWidth1: var(--sp54x);
		--minWidth2: var(--sp69x);
		--marginBottom: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--minWidth1: var(--sp48x);
		--minWidth2: var(--sp60x);
		--marginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--minWidth1: var(--sp43x);
		--minWidth2: var(--sp48x);
		--marginBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--paddingTop: var(--sp2x);
		--paddingLeft: var(--sp4x);
		--minWidth1: var(--sp36x);
		--minWidth2: var(--sp34x);
		--marginBottom: var(--sp4x);
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--marginBottom: var(--sp3x);
		.btn {
			&.filter-btn {
				padding: var(--sp1-5x) var(--sp2x);
			}
		}
	}
`
export default ButtonStyle