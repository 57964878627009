import styled from 'styled-components'


const BurgerStyle = styled.div`
    
    padding:var(--spSize) 0 var(--spSize) ;

    .Burger{
        --burgerSize:26px;
        --burgerheightSize:10px;

        cursor: pointer;
        width: var(--burgerSize);
        height: var(--burgerheightSize);
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        span{
            position: absolute;
            display:block;
            height:1px;
            background-color:var(--textColor);
            transition:all var(--trTime) ease-in-out;
            
            &#line1{
                top:0;
                left:0;
                width:43%;
                transform-origin: 70% 0%;
                transition-delay: 0.2s;
            }
            &#line2{
                top:0;
                right:0;
                width:57%;
                transform-origin: 41% 0%;
                transition-delay: 0.3s;
            }
            &#line3{
                bottom:0;
                left:0;
                width:57%;
                transform-origin: 55% 0%;
            }
            &#line4{
                bottom:0;
                right:0;
                width:43%;
                transform-origin: 30% 0%;
                transition-delay: 0.1s;
            }
            /* &:first-child{
                transform-origin: 75% 100%;
                margin-bottom: var(--sp1-3x);
            }
            &:last-child{
                transform-origin: 75% 100%;
            } */
        }

        &.active{
            span{
               
                &#line1,&#line4{
                    width: 48%;
                    transform:rotate(45deg);
                }
                &#line2,&#line3{
                    transform:rotate(-45deg);
                }
            }
        }
    }

`

export default BurgerStyle