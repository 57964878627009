import React, { Component, createContext } from 'react'

//* Hocs
import { withRouter } from 'next/router'

//* Helpers
import { pageColor } from 'helpers'

import { ThemeProvider } from 'styled-components'
import themes, { mediaQuery } from 'styles/theme'
import HelperClass from 'styles/helperClass'
import Variables from 'styles/variables'
import Typography from 'styles/typography'

//* Global Variables
const errorsInitialState = {
    formError: {
        error: false,
        names: [],
        msg: {},
    },
    systemError: {
        error: false,
        msg: '',
    },
}

const UIContext = createContext(null)

class UIProvider extends Component {
    //! States
    state = {
        theme: themes['homeTheme'],
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            screen3XL: '',
            screen2XL: '',
            screenXL: '',
            screenL: '',
            screenM: '',
            screenS: '',
            screenXS: '',
        },
        ...errorsInitialState,
        soundOfOnSwitcher: false,
        preloaderHide: false,
        preloader: true,
    }

    methods = {
        checkLoadedImage: this.checkLoadedImage.bind(this),
        soundOfOnSwitcherFun: this.soundOfOnSwitcherFun.bind(this),
    }

    popups = ['login', 'register', 'forgot-password']

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    //! Get Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            screen3XL: parseInt(root.getPropertyValue('--screen3XL')),
            screen2XL: parseInt(root.getPropertyValue('--screen2XL')),
            screenXL: parseInt(root.getPropertyValue('--screenXL')),
            screenL: parseInt(root.getPropertyValue('--screenL')),
            screenM: parseInt(root.getPropertyValue('--screenM')),
            screenS: parseInt(root.getPropertyValue('--screenS')),
            screenXS: parseInt(root.getPropertyValue('--screenXS')),
        }

        this.setState({ screenSizes })
    }

    soundOfOnSwitcherFun() {
        this.setState({ soundOfOnSwitcher: true })
    }

    getLoaderEnd() {
        setTimeout(() => {
            this.setState({ preloaderHide: true })
            setTimeout(() => {
                this.setState({ preloader: false })
            }, 500)
        }, 500)
    }

    checkLoadedImage(x) {
        if (x) {
            this.getLoaderEnd()
        }
    }

    //! Component Did Mount
    componentDidMount() {
        this.setState({
            ...this.state,
            theme: themes[pageColor(this.props.router)],
        })

        window.addEventListener('resize', this.screenResize)

        this.getScreenSizes()
        this.screenResize()

        // if (this.props.router.asPath != '/') {
        this.getLoaderEnd()
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.asPath !== this.props.router.asPath) {
            this.setState({
                ...this.state,
                theme: themes[pageColor(this.props.router)],
            })
        }
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener('keydown', this.escKeydown, false)
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                <ThemeProvider theme={{ colors: this.state.theme, mediaQuery: mediaQuery }}>
                    <Variables />
                    <Typography />
                    <HelperClass />
                    {this.props.children}
                </ThemeProvider>
            </UIContext.Provider>
        )
    }
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
