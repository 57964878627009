import styled from 'styled-components'

const VoiceStyle = styled.div`
	--paddingTop: var(--sp2x);
	--paddingLeft: var(--sp4x);
	--width:var(--sp11-5x);

	width:var(--width);
	max-height: calc(var(--width) - 3px);
	overflow: hidden;

    .LottieWrapp {
        stroke:var(--textColor);
		fill:var(--textColor);
		position: relative;
		z-index: 2;
		path{
			stroke:var(--textColor);
			fill:var(--textColor);
		}
		g{
			cursor: pointer;
		}
    }

	audio{
		position: absolute;
		top: 50%;
		left: 0;
		opacity: 0;
		transform: translateY(-50%);
		z-index: -2;
		visibility: hidden;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--width: var(--sp9x);
		--paddingTop: var(--sp1-5x);
		--paddingLeft: var(--sp3x);
	}

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--width: var(--sp7-5x);
		--paddingTop: var(--sp1-3x);
		--paddingLeft: var(--sp2-5x);
	}

    @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {
       --width:var(--sp6x);
		--paddingTop: var(--sp1-1x);
		--paddingLeft: var(--sp1-75x);

		max-height:var(--width);
    }
`

export default VoiceStyle
