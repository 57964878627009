import styled from 'styled-components'

const BackToStyle = styled.section.attrs()`
	--btnIconSize: var(--sp8x);
	--btnMarginRigh: var(--sp4x);

	width: 100%;

	.back-btn {
		display: flex;
		align-items: center;
		margin-top: calc(var(--containerMarginTop)*0.5);

		i {
			display: block;
			font-size: var(--btnIconSize);
			transform: rotate(180deg);
			margin-right: var(--btnMarginRigh);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--btnIconSize: var(--sp7x);
		--btnMarginRigh: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--btnIconSize: var(--sp6x);
		--btnMarginRigh: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--btnIconSize: var(--sp5x);
		--btnMarginRigh: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--btnIconSize: var(--sp4x);
		--btnMarginRigh: var(--sp2x);
	}
`

export default BackToStyle
