import React, { memo as Memo, useState, useRef, useEffect, useMemo } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Helpers
import { randomIntFromInterval } from 'helpers'

//* Components
import { Image, Text, Icon } from 'components/common'

//* Style
import GalleryItemStyle from './style'


const GalleryItem = Memo(({ winWidth, winHeight, ...props }) => {
    const ref = useRef();
    const [activePhoto, setActivePhoto] = useState('');
    const [infoState, setInfoState] = useState('');
    const [textsActive, setTextsActive] = useState('');

    useEffect(() => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.7) {
            setTimeout(() => {
                setActivePhoto('active')
            }, 300);
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }
    }, [])

    const scroll = () => {
        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.7) {
            setActivePhoto('active')
        }

        if (ref?.current?.getBoundingClientRect().y < winHeight * 0.35) {
            setTextsActive('active')
        }
    }

    const storeLoadImg = useMemo(() => {
        return `/images/LoadImgs/${randomIntFromInterval(1, 11)}.png`
    }, [])

    return (
        <GalleryItemStyle ref={ref} className={`gallery-item FlexBoxColumn ${textsActive} ${props.type}`}>
            <div className='images'>
                <Image className="load-img" src={storeLoadImg} />
                <Image className={`gal-img ${activePhoto}`} src={props.src} alt='featured image' />
            </div>
            <div className='info FlexBox spaceBetween'>
                <Text tag={'p'} className={'title uppercase pM GHEAMariamBold'} text={props.title} />
                <Text tag={'p'} className={'year pM NotoSansRegular'} text={props.year} />
            </div>
            <div className='cat-info FlexBox spaceBetween alignMiddle'>
                <Text tag={'p'} className={'category pM NotoSansRegular'} text={props.category} />
                {winWidth > 1279 ?
                    <div className='more-info-btn FlexBox alignMiddle' onMouseEnter={() => setInfoState('active')} onMouseLeave={() => setInfoState('')}>
                        <Icon className='icon-star ' />
                        <Text tag={'p'} className={'text pXS NotoSansRegular uppercase'} text={'moreInfo'} />
                    </div>
                    :
                    <div className='more-info-btn FlexBox alignMiddle' onClick={() => setInfoState(infoState === '' ? 'active' : '')}>
                        <Icon className='icon-star ' />
                        <Text tag={'p'} className={'text pXS NotoSansRegular uppercase'} text={infoState === '' ? 'moreInfo' : 'lessInfo'} />
                    </div>
                }
            </div>
            <div className={`more-info ${infoState}`}>
                <Text tag={'p'} className={'more-info-text pXS NotoSansRegular'} text={props.moreInfo} />
            </div>
        </GalleryItemStyle>
    )
})

export default withUIContext(GalleryItem, ['winWidth', 'winHeight']);