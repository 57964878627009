import React, { memo as Memo, useState, useEffect } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Text, Icon, Image } from 'components/common'

//* Style
import SoundOnOffStyle from './style'

const SoundOnOff = Memo(({ soundOfOnSwitcherFun, soundOfOnSwitcher, ...props }) => {
	const [playAnim, setPlayAnim] = useState(false)

	useEffect(() => {
		let timeout = setTimeout(() => {
			setPlayAnim(!playAnim)
		}, 2000)

		return () => {
			clearTimeout(timeout)
		}
	})

	return !soundOfOnSwitcher ? (
		<SoundOnOffStyle>
			<div className={'contentWrap FlexBoxColumn'}>
				<Image priority={'high'} src={'/images/SoundOnOff/fork.png'} className='fork' />
				<Text tag={'h4'} className={'GHEAMariamBold uppercase'} text={'Sergey'} />
				<Text tag={'p'} className={'pL GHEAMariamBold uppercase'} text={'Parajanov'} />
				<div>
					<Image priority={'high'} src={'/images/SoundOnOff/eye.png'} className='eye' />
					<Image priority={'high'} src={'/images/SoundOnOff/eyeBg.png'} className='eye eyeBg' />
					<Image priority={'high'} src={'/images/SoundOnOff/eyeBall.png'} className='eyeBall' />
				</div>
				<Image priority={'high'} src={'/images/SoundOnOff/ear.png'} className={`ear ${playAnim ? `active` : ''}`} />

				<div className='FlexBox alignMiddle museum'>
					<Image priority={'high'} src={'/images/SoundOnOff/nose.png'} />
					<Icon className='icon-star2' />
					<Text tag={'p'} className={'pL GHEAMariamBold museumText uppercase'} text={'Museum'} />
				</div>
			</div>
			<Text tag={'p'} className={'pM NotoSansRegular welcomeText'} text={'SoundText'} />
			<span className='arrow' onClick={() => soundOfOnSwitcherFun()}>
				<Icon className={'icon-arrowRight '} />
			</span>
		</SoundOnOffStyle>
	) : (
		''
	)
})

export default withUIContext(SoundOnOff, ['soundOfOnSwitcherFun', 'soundOfOnSwitcher'])
