import React, { memo as Memo, useRef, useCallback, useState, useEffect } from 'react';
import { gsap } from 'gsap'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Image, Text } from 'components/common'

//* Style
import CursorStyle from './style'

const Cursor = Memo((props) => {

    const [clickDownUp, setClickDownUp] = useState(false)
    const [cursorPlace, setCursorPlace] = useState('')
    const [clickText, setClickText] = useState(props.text)

    const Cursor1Ref = useRef()
    const Cursor2Ref = useRef()
    const Cursor3Ref = useRef()

    const mouseOver = useCallback((e) => {
        if (e.clientX) {
            if (e.target.closest('a')) {
                setClickText(e.target.closest('a').attributes.id.value)
            }

            gsap.to([Cursor1Ref.current, Cursor2Ref.current, Cursor3Ref.current], 0.2, {
                stagger: 0.05,
                top: e.clientY,
                left: e.clientX,
                position: 'fixed',
                scale: 1,
            })

            if (props.typeCursor != "home") {
                if (e.clientX < window.innerWidth / 2 && props.reverse) {
                    setCursorPlace('left')
                    setClickText('previous')
                } else {
                    setCursorPlace('')
                    setClickText('next')
                }
            }
        }
    }, [props.winWidth, Cursor1Ref, Cursor2Ref, Cursor3Ref])

    const mouseOut = useCallback(() => {
        gsap.to([Cursor1Ref.current, Cursor2Ref.current, Cursor3Ref.current], {
            stagger: 0.05,
            scale: 0
        })
    }, [Cursor1Ref, Cursor2Ref, Cursor3Ref])


    useEffect(() => {
        setClickDownUp(false)
    }, [])


    const ClickDownUp = (e) => {
        setClickDownUp(true)
        const timer = setTimeout(() => {
            setClickDownUp(false)
        }, 300)

        return () => clearTimeout(timer)
    }



    return (
        <CursorStyle className={`cursorWrapp ${props.reverse ? 'reverseColors' : ''} ${cursorPlace}`} onMouseMove={e => mouseOver(e)} onMouseLeave={e => mouseOut(e)} onMouseDown={ClickDownUp}>
            {
                props.winWidth >= 1024 ?
                    <div className={`cursorDrag ${clickDownUp ? "active" : ""}`} >
                        <div className='circleWrap' ref={Cursor1Ref}>
                            <div className='circle'></div>
                        </div>
                        <div className={'imageWrap FlexBoxColumn alignMiddle  alignCenter '} ref={Cursor2Ref}>
                            <Image className='Hand1' src={cursorPlace === '' ? '/images/cursorIcon/Hand1.png' : '/images/cursorIcon/Hand1Left.png'} alt={'Hand'} />
                            <Image className='Hand2' src={cursorPlace === '' ? '/images/cursorIcon/Hand2.png' : '/images/cursorIcon/Hand2Left.png'} alt={'Hand'} />
                        </div>
                        <span ref={Cursor3Ref}>
                            <Text className={'GHEAMariamBold uppercase '} text={clickText} />
                        </span>
                    </div>
                    :
                    ''}
            {props.children}
        </CursorStyle>
    )
})

export default withUIContext(Cursor, ['winWidth'])