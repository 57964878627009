import styled from 'styled-components'


const ShareIconStyle = styled.a.attrs(props => ({ className: props.className,href:props.href,ref:props.ref }))`

    --shareLinkSize:var(--sp4x);
    --shareLinkRight:var(--sp8x);
    --shareLinkPadding:var(--sp3x);
    --socIconHeight:var(--sp10x);
    --socIconWidth:var(--sp6x);

    padding:var(--shareLinkPadding) 0 ;
    font-size:var(--shareLinkSize);
    margin-right:var(--shareLinkRight);
    position: relative;
    
    &:after{
        content:"";
        position: absolute;
        top:50%;
        left:50%;
        height:var(--socIconHeight);
        width:var(--socIconWidth);
        transform: translateX(-50%) translateY(-50%) rotate(36deg);
        border:1px solid var(--textColor);
        border-radius: 50%;
    }
    &:last-child{
        margin-right:0;
    }


    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
            --shareLinkSize:var(--sp3x);
            --shareLinkRight:var(--sp7x);
            --shareLinkPadding:var(--sp2-5x);
            --socIconHeight:var(--sp8x);
            --socIconWidth:var(--sp4-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
            --shareLinkSize:var(--sp3x);
            --shareLinkRight:var(--sp6x);
            --shareLinkPadding:var(--sp2-5x);
            --socIconHeight:var(--sp8x);
            --socIconWidth:var(--sp4-5x);
		
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
        --shareLinkSize:var(--sp2-5x);
        --shareLinkRight:var(--sp5x);
        --shareLinkPadding:var(--sp2x);
        --socIconHeight:var(--sp6-5x);
        --socIconWidth:var(--sp3-5x);	
	}

    

`

export default ShareIconStyle