import React, { memo as Memo, useState, useEffect } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Page, Text, Button, Icon, Container, AnimationWrapp } from 'components/common'

//* Styles
import DonateStyle from 'styles/pages/DonateStyle'

const SuccessMessage = Memo(({ selectedLang, ...props }) => {

    //! States
    const [secObj, setSecObj] = useState()

    useEffect(() => {
        setSecObj([".otherElementAnimation"])
    }, [selectedLang])


    return (
        <Page>
            <AnimationWrapp state={secObj} >
                <Container className="containerPaddTopBottom">
                    <DonateStyle className="textAlignCenter">
                        <div className="FlexBox alignCenter overflowHidden margBottomTitle">
                            <span className={'otherElementAnimation'}>
                                <Icon className={'icon-star2'} />
                            </span>
                        </div>
                        <div className="FlexBox alignCenter overflowHidden margBottomTitle">
                            <Text tag={"h4"} className={'otherElementAnimation GHEAMariamBold uppercase'} text={props.title} />
                        </div>
                        <Text tag={'p'} className={'pL NotoSansRegular'} text={props.description} />
                        <div className="FlexBoxContainer alignCenter overflowHidden">
                            <Button text={props.backToText} link={props.backTo} btnAnimation={true} wrappClassName={"otherElementAnimation"} />
                        </div>
                    </DonateStyle>
                </Container>
            </AnimationWrapp>
        </Page>
    )
})

export default withLanguageContext(SuccessMessage, ['selectedLang'])