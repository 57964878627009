import React, { useRef, useImperativeHandle, forwardRef, useMemo } from 'react'

//* Components
import { Icon, Translate } from 'components/common'

//* Styles
import TitleStyle from './style'

const Title = forwardRef((props, ref) => {

    //!Ref
    const titleRef = useRef()

    //! Button Type
    const title = useMemo(() => {
        return (
            Object.values(props.title).map((items, index) => {
                return (
                    <div key={index} className={`FlexBox alignEnd ${props.className}`}>

                        {
                            Object.values(items).map((item, key) => {
                                if (item.type == 'big') {
                                    return (
                                        <span key={key} className={`h1 GHEAMariamBold uppercase ${props.childClassName}`} >
                                            <Translate val={item.name} />{item.space ? <>&nbsp;</> : ''}
                                        </span>
                                    )
                                }
                                else if (item.type == 'min') {
                                    return (
                                        <span key={key} className={`h4 NotoSansRegular uppercase`} >
                                            <Translate val={item.name} />{item.space ? <>&nbsp;</> : ''}
                                        </span>
                                    )
                                }
                                else if (item.type == 'icon') {
                                    return (
                                        <span className="iconWrapp" key={key}>
                                            <Icon key={key} className={'icon-starIconTitle'} />
                                        </span>
                                    )
                                }
                            })
                        }
                    </div>
                )
            })
        )
    }, [props])

    useImperativeHandle(ref, () => [titleRef.current.childNodes], [])

    return (
        <TitleStyle className={`Title ${props.wrapClassName}`} ref={titleRef}>
            {title}
        </TitleStyle>
    )
})

export default Title