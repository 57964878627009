import React, { memo as Memo, useMemo, useState } from 'react'

//* HOCs
import { withLanguageContext } from 'context'

//* Components
import { CustomLink, Image, LottieAnimation, Translate } from 'components/common'

//* Style
import ButtonStyle from './style'
import BtnLottie from 'lottieAnimation/btnAnimation.json'

const Button = Memo((props) => {
    const [playState, setPlaySate] = useState('')

    //! Button Type
    const btn = useMemo(() => {
        if (props.link) {
            if (props.btnFrame) {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`}>
                        <CustomLink url={props.link} className={`btn NotoSansRegular btnFrame pM uppercase ${props.className || ''}`}
                            onMouseOut={props.onMouseOut} onMouseEnter={props.onMouseEnter}>
                            {props.children || <Translate val={props.text} />}
                            <Image className='TopLine' src={'/images/btn/btnBorderTop.png'} alt={'button border'} />
                            <Image className='LeftLine' src={'/images/btn/btnBorderLeft.png'} alt={'button border'} />
                            <Image className='BottomLine' src={'/images/btn/btnBorderTop.png'} alt={'button border'} />
                            <Image className='RightLine' src={'/images/btn/btnBorderLeft.png'} alt={'button border'} />
                        </CustomLink>
                    </ButtonStyle>
                )
            } else if (props.btnAnimation) {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`} >
                        <CustomLink
                            url={props.link}
                            className={`btn NotoSansRegular btnAnimation pM uppercase ${props.className || ''}`}
                            onMouseEnter={() => setPlaySate(true)}
                            onMouseOut={() => setPlaySate(false)}
                        >
                            {props.children || <Translate val={props.text} />}
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                        </CustomLink>
                    </ButtonStyle>
                )
            }
            else {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`} >
                        <button
                            className={`btn NotoSansRegular pM uppercase ${props.className || ''}`}
                            type={props.type || 'button'}
                            title={props.title || ''}
                            aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                            onClick={props.onClick}
                            onMouseEnter={() => setPlaySate(true)}
                            onMouseOut={() => setPlaySate(false)}
                        >
                            {props.children || <Translate val={props.text} />}
                        </button>
                    </ButtonStyle>
                )
            }
        } else {
            if (props.btnFrame) {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`}>
                        <button
                            className={`btn NotoSansRegular btnFrame pM uppercase ${props.className || ''}`}
                            type={props.type || 'button'}
                            title={props.title || ''}
                            aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                            onClick={props.onClick}
                        >
                            {props.children || <Translate val={props.text} />}
                            <Image className='TopLine' src={'/images/btn/btnBorderTop.png'} alt={'button border'} />
                            <Image className='LeftLine' src={'/images/btn/btnBorderLeft.png'} alt={'button border'} />
                            <Image className='BottomLine' src={'/images/btn/btnBorderTop.png'} alt={'button border'} />
                            <Image className='RightLine' src={'/images/btn/btnBorderLeft.png'} alt={'button border'} />
                        </button>
                    </ButtonStyle>
                )
            }
            else if (props.btnAnimation) {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`} >
                        <button
                            className={`btn NotoSansRegular btnAnimation pM uppercase ${props.className || ''}`}
                            type={props.type || 'button'}
                            title={props.title || ''}
                            aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                            onClick={props.onClick}
                            onMouseEnter={() => setPlaySate(true)}
                            onMouseOut={() => setPlaySate(false)}
                        >
                            {props.children || <Translate val={props.text} />}
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                            <LottieAnimation animData={BtnLottie} isPlay={playState} isReverse={!playState} autoplay={false} loop={false} />
                        </button>
                    </ButtonStyle>
                )
            }
            else {
                return (
                    <ButtonStyle className={`btn-cont ${props.wrappClassName ? props.wrappClassName : ''}`} >
                        <button
                            className={`btn NotoSansRegular pM uppercase ${props.className || ''}`}
                            type={props.type || 'button'}
                            title={props.title || ''}
                            aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                            onClick={props.onClick}
                            onMouseEnter={() => setPlaySate(true)}
                            onMouseOut={() => setPlaySate(false)}
                        >
                            {props.children || <Translate val={props.text} />}
                        </button>
                    </ButtonStyle>
                )
            }
        }
    }, [props, playState])

    return btn
})
export default withLanguageContext(Button, ['translate', 'selectedLang'])