import styled from 'styled-components'

const CursorStyle = styled.div`
	--circleHeight: var(--sp22x);
	--circleWidth: var(--sp57x);
	--textFontSize: 18px;
	--handWidthSize: var(--sp19x);
	--handHeightSize: var(--sp14x);

	&.reverseColors {
		&.cursorWrapp .cursorDrag {
			.circleWrap {
				.circle {
					border: 1px solid var(--backColor);
				}
			}
		}

		.comp-title {
			color:var(--backColor)
		}
	}

	&.left {
		&.cursorWrapp {
			.cursorDrag {
				.circleWrap .circle {
					transform: rotate(29deg);
				}

				.imageWrap {
					.Hand1 {
						transform: rotate(380deg);
					}
				}
			}
		}
	}

	&.cursorWrapp {
		position: relative;
		
		&.reverseColors.left {
			.cursorDrag {
				&.active {
					.circleWrap .circle {
						transform: rotate(29deg) scale(0.8);
					}

					p {
						transform: scale(0.8);
					}
					.Hand1 {
						opacity: 0;
					}
					.Hand2 {
						opacity: 1;
						img {
							transform: scale(0.8);
						}
					}
				}
			}
		}

		.cursorDrag {
			.circleWrap {
				pointer-events: none;
				position: absolute;
				top: 10%;
				left: 10%;
				z-index: 6;
				width: var(--circleWidth);
				height: var(--circleHeight);
				transform:translate(-45%, -52%) scale(0);
				transform-origin: center;
				.circle {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 1px solid var(--textColor);
					border-radius: 70%;
					transform-origin: center;
					transform: rotate(-29deg);
					transition: all var(--trTime) ease;
				}
			}

			.imageWrap {
				pointer-events: none;
				position: absolute;
				text-align: center;
				z-index: 7;
				top: 11%;
				left: 13%;
				transform: translate3d(-55%, -60%, 0) scale(0);
				transform-origin: 72% 50%;
				.Hand1 {
					transform: rotate(325deg);
					opacity: 1;
				}
				.Hand2 {
					opacity: 0;
				}
				.Hand1,
				.Hand2 {
					width: var(--handWidthSize);
					height: var(--handHeightSize);
					img {
						object-fit: contain;
						transition: all var(--trTime) ease;
						transform-origin: 85% 50%;
					}
				}
				.Hand2 {
					position: absolute;
					top: 0%;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			> span {
				pointer-events: none;
				position: absolute;
				text-align: center;
				z-index: 7;
				top: 7%;
				left: 13%;
				transform: translate(-60%, 100%) scale(0);
				transform-origin: 85% -160%;
				p {
					transform-origin: 80% -160%;
					transition: all var(--trTime) ease;
					font-size: var(--textFontSize);
				}
			}

			&.active {
				.circleWrap .circle {
					transform: rotate(-29deg) scale(0.8);
				}

				p {
					transform: scale(0.8);
				}
				.Hand1 {
					opacity: 0;
				}
				.Hand2 {
					opacity: 1;
					img {
						transform: scale(0.8);
					}
				}
			}
		}
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeM}) {
			--circleHeight: var(--sp17x);
			--circleWidth: var(--sp42x);
			--textFontSize: 16px;
			--handWidthSize: var(--sp14-5x);
			--handHeightSize: var(--sp11x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeS}) {
			--circleHeight: var(--sp15x);
			--circleWidth: var(--sp36x);
			--textFontSize: 14px;
			--handWidthSize: var(--sp12-5x);
			--handHeightSize: var(--sp9x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.DesktopSizeXS}) {
			--circleHeight: var(--sp14x);
			--circleWidth: var(--sp35x);
			--textFontSize: 14px;
			--handWidthSize: var(--sp12x);
			--handHeightSize: var(--sp8x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.LaptopSize}) {
			--circleHeight: var(--sp12x);
			--circleWidth: var(--sp33x);
			--textFontSize: 13px;
			--handWidthSize: var(--sp10x);
			--handHeightSize: var(--sp7x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
				props.theme.mediaQuery.TabletSize}) {
		}
	}
`
export default CursorStyle
