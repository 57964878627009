import styled from 'styled-components'

const ImgTextCompStyle = styled.div.attrs()`
	--smallImageTopMarg: var(--sp25x);
	--supportFirstImgPadd: calc(var(--sp33x) - var(--containerPadding));
	--supportFirstArticlePaddLeft: var(--sp9x);
	--supportFirstImgSize: 55.7%;
	--supportFirstInfo: calc(100% - var(--supportFirstImgSize));
	--supportSecondImgSize: 13.5% 9.5%;

	--supportSecondSectiionImgSize: 55%;
	--descriptionWidth: 70%;

	.reversColor {
		background-color: var(--textColor);
		color: var(--backColor);
	}

	.bigImageWrapp {
		.imageCont {
			padding-top: 100%;
			img {
				transition: var(--trTime);
				clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
			}
			&.active {
				img {
					clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
				}
			}
		}
	}

	&.supportFirstSectiion {
		.bigImageWrapp {
			width: var(--supportFirstImgSize);
			padding-left: var(--supportFirstImgPadd);
			.imageCont {
				padding-top: 67%;
			}
		}
		.info {
			padding-left: var(--supportFirstArticlePaddLeft);
			width: var(--supportFirstInfo);
		}

		.smallImageWrapp {
			width: 100%;
			margin-top: var(--smallImageTopMarg);
			padding-right: var(--supportFirstImgPadd);
			.imageCont {
				padding: var(--supportSecondImgSize);
				img {
					transition: var(--trTime);
					clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
				}
				&.active {
					img {
						clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
					}
				}
			}
		}
	}

	&.supportSecondSectiion {
		.bigImageWrapp {
			width: var(--supportSecondSectiionImgSize);
			.imageCont {
				padding-top: 95.7%;
				img {
					border-top-left-radius: 50%;
					border-top-right-radius: 50%;
				}
			}
		}
	}

	.info {
		padding-left: var(--supportFirstArticlePaddLeft);
		width: var(--supportFirstInfo);

		.fromLeft {
			width: var(--descriptionWidth);

			font-size: var(--pM);
			font-family: NotoSansRegular;
			p {
				font-size: var(--pM);
				font-family: NotoSansRegular;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--smallImageTopMarg: var(--sp15x);
		--supportFirstImgPadd: calc(var(--sp27x) - var(--containerPadding));
		--supportFirstArticlePaddLeft: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--smallImageTopMarg: var(--sp14x);
		--supportFirstImgPadd: calc(var(--sp19x) - var(--containerPadding));
		--supportFirstArticlePaddLeft: var(--sp6x);
		--supportFirstImgSize: 57.7%;
		--supportSecondImgSize: 14.5% 10%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--smallImageTopMarg: var(--sp13x);
		--supportFirstImgPadd: calc(var(--sp16x) - var(--containerPadding));
		--supportFirstArticlePaddLeft: var(--sp6x);
		--supportFirstImgSize: 57%;
		--supportSecondImgSize: 14% 10.5%;
		--descriptionWidth: 100%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--smallImageTopMarg: var(--sp12x);
		--supportFirstImgPadd: calc(var(--sp14x) - var(--containerPadding));
		--supportFirstArticlePaddLeft: var(--sp4x);
		--supportFirstImgSize: 50%;
		--supportSecondImgSize: 16% ​11.5%;
		--supportSecondSectiionImgSize: 50%;
		--descriptionWidth: 100%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		&.supportFirstSectiion {
			.bigImageWrapp {
				padding-left: 0;
				margin-bottom: var(--supportFirstImgPadd);
			}
		}

		&.supportSecondSectiion {
			.bigImageWrapp {
				margin-bottom: var(--supportFirstImgPadd);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--smallImageTopMarg: var(--sp12x);
		--supportFirstImgPadd: var(--sp7x);
		--supportFirstArticlePaddLeft: 0;
		--supportFirstImgSize: 90%;
		--supportFirstInfo: 64%;
		--supportSecondImgSize: 76% 16%;
		--supportSecondSectiionImgSize: 100%;
		--descriptionWidth: 100%;

		&.supportFirstSectiion {
			.smallImageWrapp {
				width: calc(100% - var(--supportFirstInfo));
				padding-left: var(--supportFirstImgPadd);
				padding-right: 0;
				.imageCont {
					width: 100%;
				}
			}
		}

		&.supportSecondSectiion {
			.info {
				padding-left: 0;
				width: var(--supportSecondSectiionImgSize);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--smallImageTopMarg: var(--sp6x);
		--supportFirstImgPadd: var(--sp6x);
		--supportFirstArticlePaddLeft: 0;
		--supportFirstImgSize: 100%;
		--supportFirstInfo: 100%;
		--supportSecondImgSize: 35% 25%;
		--supportSecondSectiionImgSize: 100%;
		--descriptionWidth: 100%;

		&.supportFirstSectiion {
			.smallImageWrapp {
				padding-right: 0;
			}
		}

		&.supportSecondSectiion {
			.info {
				padding-left: 0;
				width: var(--supportSecondSectiionImgSize);
			}
		}
	}
`

export default ImgTextCompStyle
