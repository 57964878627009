import React, { memo as Memo, useMemo, useState, useEffect } from 'react'

//* HOCs
import { withUIContext, withCartContext } from 'context'
import { useRouter } from 'next/router'

//* Components
import { CustomLink, Text, Icon } from 'components/common'
import Burger from 'components/common/AppHeader/HeaderComponent/Burger'
import AnimateHeight from 'react-animate-height';

//* Styles
import CartSlideStyle from './style'

const CartSlide = Memo(({ items_quantity, items, subtotal, total, productIds, cartOpened, toggleCart, removeCart, addToCart, winWidth, ...props }) => {
    const router = useRouter()
    const [height, setHeight] = useState(0)

    // useEffect(() => {
    //     setHeight(0)
    // }, [router.pathname])

    useEffect(() => {
        setHeight(cartOpened ? 'auto' : 0)
    }, [cartOpened])

    const storeCart = useMemo(() => {
        return items.map((i, k) => (
            <div className='single-product FlexBox alignMiddle' key={k}>
                <Text tag={'p'} className={'item uppercase pL NotoSansRegular'} text={i.product.name} />
                <div className='qty cont FlexBox alignMiddle'>
                    <Text tag={'p'} className={'p NotoSansRegular'} text={'-'}
                        onClick={i.quantity > 1 ? () => addToCart(i.product.id, i.quantity - 1, i.product.price, i.product.size, i.product.name, i.product.featured_image) : null} />
                    <Text tag={'p'} className={'quantity pL NotoSansRegular'} text={i.quantity} />
                    <Text tag={'p'} className={'p NotoSansRegular'} text={'+'}
                        onClick={() => addToCart(i.product.id, i.quantity + 1, i.product.price, i.product.size, i.product.name, i.product.featured_image)} />
                </div>
                <Text tag={'p'} className={'size uppercase pL NotoSansRegular'} text={i.product.size} />
                <Text tag={'p'} className={'price uppercase pL NotoSansRegular'} text={`${i.product.price}AMD`} />
                {winWidth > 480 ?
                    <Text onClick={() => removeCart(i.product.id, i.product.size)} tag={'p'} className={'remove uppercase pL NotoSansRegular'} text={'remove'} />
                    :
                    <Icon onClick={() => removeCart(i.product.id, i.product.size)} className='icon-bucket remove' />
                }
            </div>
        ))
    }, [items])

    return (
        <CartSlideStyle>
            <AnimateHeight
                duration={200}
                height={height}
            >
                <div className={'cart-block FlexBoxColumn'}>
                    <div className='heading FlexBox'>
                        <Text tag={'p'} className={'item uppercase pM GHEAMariamBold'} text={'item'} />
                        <Text tag={'p'} className={'qty uppercase pM GHEAMariamBold'} text={'quantity'} />
                        <Text tag={'p'} className={'size uppercase pM GHEAMariamBold'} text={'sizes'} />
                        <Text tag={'p'} className={'price uppercase pM GHEAMariamBold'} text={'price'} />
                        <Burger onClick={() => toggleCart()} checked={true} />
                    </div>
                    <div className='main-cart FlexBoxColumn'>
                        {storeCart}
                    </div>
                    <div className='total FlexBoxColumn'>
                        <div className='row FlexBox'>
                            <Text tag={'p'} className={'title uppercase pL NotoSansRegular'} text={'subtotal'} />
                            <Text tag={'p'} className={'value uppercase pL NotoSansRegular'} text={`${subtotal}AMD`} />
                        </div>
                        <div className='row FlexBox'>
                            <Text tag={'p'} className={'title uppercase pL NotoSansRegular'} text={'shippingCost'} />
                            <Text tag={'p'} className={'value uppercase pL NotoSansBold'} text={'-'} />
                        </div>
                        <div className='row FlexBox'>
                            <Text tag={'p'} className={'title uppercase pL NotoSansRegular'} text={'Total'} />
                            <Text tag={'p'} className={'value uppercase pL NotoSansBold'} text={`${total}AMD`} />
                        </div>
                    </div>
                    <Text tag={'p'} className={'ship-text capitalize pM NotoSansRegular'} text={'shippingText'} />
                    <CustomLink onClick={() => toggleCart()} url='/checkout' className='to-checkout FlexBox alignCenter' options={{ disabled: items.length <= 0 }}>
                        <Text tag={'p'} className={'NotoSansRegular pL uppercase'} text={'checkout'} />
                    </CustomLink>
                </div>
            </AnimateHeight>
        </CartSlideStyle>
    )
})

export default withUIContext(withCartContext(CartSlide, ['items_quantity', 'items', 'subtotal', 'total', 'productIds', 'cartOpened', 'toggleCart', 'removeCart', 'addToCart']), ['screenSizes', 'winWidth'])