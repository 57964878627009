import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withDataContext } from 'context'

//*Component
import { LanguageSwitcher, Logo, Container, ButtonTickets, Text, Translate, Icon, Menu, SocIcon } from 'components/common'

//* JSON's
import config from 'helpers/_config'

//* Styles
import Footer from './style'

const AppFooter = Memo(({ global, selectedLang, ...props }) => {
    //! Routes from Config
    const routes = useMemo(() => config.routes, [])

    //! Page Data
    const pageData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Big Menu Object
    const menuObj1 = useMemo(
        () => ({
            contClass: 'menu-cont',
            itemClass: 'menu-item pL NotoSansRegular',
            items: [
                {
                    name: routes.aboutMuseum.name,
                    path: routes.aboutMuseum.path,
                },
                {
                    name: routes.exhibitions.name,
                    path: routes.exhibitions.path,
                },
                {
                    name: routes.news.name,
                    path: routes.news.path,
                },
                {
                    name: routes.shop.name,
                    path: routes.shop.path,
                },
                {
                    name: routes.supportUs.name,
                    path: routes.supportUs.path,
                },
            ],
        }),
        [routes]
    )

    const menuObj2 = useMemo(
        () => ({
            contClass: 'menu-cont',
            itemClass: 'menu-item pL NotoSansRegular',
            items: [
                // {
                // 	name: 'AboutParajanov',
                // 	path: "http://parajanovmuseum.am/hy/sergei-parajanov/bio",
                // },
                {
                    name: routes.gallery.name,
                    path: routes.gallery.path,
                },
                {
                    name: routes.films.name,
                    path: routes.films.path,
                },
                {
                    name: routes.terms.name,
                    path: routes.terms.path,
                },
            ],
        }),
        [routes]
    )

    return (
        <Footer className='footer'>
            <Icon className='icon-star' />

            {props.winWidth >= 768 ? (
                <div className='footerTop'>
                    <div className='footerTitleWrap'>
                        <Container className={'FlexBoxContainer spaceBetween alignMiddle'}>
                            {props.winWidth >= 1280 ? (
                                <div className='menuTitle'>
                                    <Logo className={'footerLogo '} />
                                </div>
                            ) : (
                                ''
                            )}
                            <div className='menuTitle'>
                                <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Museum' />
                            </div>

                            <div className='menuTitle'>
                                <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Parajanov' />
                            </div>

                            <div className='menuTitle'>
                                <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Contact' />
                            </div>

                            <div className='menuTitle'>
                                <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='FollowUs' />
                            </div>
                        </Container>
                    </div>
                    <Container>
                        <div className='footerMenuItemsWrap FlexBoxContainer spaceBetween'>
                            {props.winWidth >= 1280 ? <div className='menuItem'></div> : ''}
                            <div className='menuItem'>
                                <Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj1} />
                            </div>

                            <div className='menuItem'>
                                <Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj2} />
                            </div>

                            <div className='menuItem'>
                                <Text tag={'span'} className='pM NotoSansRegular contactItem' text={pageData?.address} />
                                <a
                                    href={`tel:${pageData?.phones}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pM NotoSansRegular contactItem'
                                >
                                    {pageData?.phones}
                                </a>
                                <a
                                    href={`mailto:${pageData?.email}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pM NotoSansRegular contactItem'
                                >
                                    {pageData?.email}
                                </a>
                            </div>

                            <div className='menuItem'>
                                <div className='socWrap'>
                                    <SocIcon items={pageData?.socials} />
                                    <LanguageSwitcher />
                                    <a className='nft-button pL' target='_blank' href="https://parajanovnft.io/">Parajanov NFT</a>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            ) : (
                <>
                    <div className='footerTop'>
                        <div className='footerTitleWrap'>
                            <Container className={'FlexBoxContainer spaceBetween alignMiddle'}>
                                <div className='menuTitle'>
                                    <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Museum' />
                                </div>

                                <div className='menuTitle'>
                                    <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Parajanov' />
                                </div>
                            </Container>
                        </div>
                        <Container>
                            <div className='footerMenuItemsWrap FlexBoxContainer spaceBetween'>
                                <div className='menuItem'>
                                    <Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj1} />
                                </div>

                                <div className='menuItem'>
                                    <Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj2} />
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className='footerTop'>
                        <div className='footerTitleWrap'>
                            <Container className={'FlexBoxContainer spaceBetween alignMiddle'}>
                                <div className='menuTitle'>
                                    <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='Contact' />
                                </div>

                                <div className='menuTitle'>
                                    <Text tag={'p'} className={'p uppercase GHEAMariamBold'} text='FollowUs' />
                                </div>
                            </Container>
                        </div>
                        <Container>
                            <div className='footerMenuItemsWrap FlexBoxContainer spaceBetween'>
                                <div className='menuItem'>
                                    <Text tag={'span'} className='pM NotoSansRegular contactItem' text={pageData?.address} />
                                    <a
                                        href={`tel:${pageData?.phones}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='pM NotoSansRegular contactItem'
                                    >
                                        {pageData?.phones}
                                    </a>
                                    <a
                                        href={`mailto:${pageData?.email}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='pM NotoSansRegular contactItem'
                                    >
                                        {pageData?.email}
                                    </a>
                                </div>

                                <div className='menuItem'>
                                    <div className='socWrap'>
                                        <SocIcon items={pageData?.socials} />
                                        <LanguageSwitcher />
                                        <a className='nft-button pM' href="https://parajanovnft.io/" target='_blank'>Parajanov NFT</a>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </>
            )}
            <Container>
                <div className='TicketsWrapp FlexBox justifyEnd'>
                    <ButtonTickets />
                </div>
            </Container>
            <Container className={'footerBottom FlexBoxContainer spaceBetween alignMiddle'}>
                <Text tag={'span'} className={'FlexBox alignMiddle pM NotoSansRegular'}>
                    © {new Date().getFullYear()} <Translate val={'AllRightsReserve'} />
                </Text>

                <Text tag={'span'} className={'pM NotoSansRegular'}>
                    <Translate val={'MadeBy'} />
                    <a className='pM NotoSansRegular underline' target='_blank' rel='noopener noreferrer' href='https://conceptstudio.com/'>
                        Concept Studio
                    </a>
                    &nbsp;
                    {selectedLang === 'hy' ? null : <Translate val={'supportedBy'} />}
                    <a className='pM NotoSansRegular underline' target='_blank' rel='noopener noreferrer' href='https://jti.com/'>
                        <Translate val={'jti'} />
                    </a>
                    &nbsp;
                    <Translate val={'closedCompany'} />
                    {selectedLang === 'hy' ? <Translate val={'supportedBy'} /> : null}
                </Text>
            </Container>
        </Footer>
    )
})

export default withLanguageContext(withUIContext(withDataContext(AppFooter, ['global']), ['winWidth']), ['translate', 'selectedLang'])
