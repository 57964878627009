import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { usePageVisibility } from 'react-page-visibility';
import { withUIContext } from 'context'

import { LottieAnimation } from 'components/common'


//* Styles
import VoiceStyle from './style'
import soundOnOf from 'lottieAnimation/soundOnOfOld.json'

const Voice = Memo((props) => {

	const [soundState, setSoundSate] = useState(false)
	const [soundLastState, setSoundLastSate] = useState(false)
	const soundRef = useRef()

	const isVisible = usePageVisibility()

	const PlayPause = () => {
		if (isVisible && soundState === false) {
			soundRef.current.play()
			setSoundSate(true)
			setSoundLastSate(true)
		}
		else {
			soundRef.current.pause()
			setSoundSate(false)
			setSoundLastSate(false)
		}
	}

	useEffect(() => {
		if(props.soundOfOnSwitcher){
			if(soundLastState){
				PlayPause()
				setSoundLastSate(true)
			}
			else{
				soundRef.current.pause()
				setSoundSate(false)
			}
		}
	}, [isVisible])

	useEffect(() => {
		props.soundOfOnSwitcher &&
		soundRef.current.play(),
		setSoundSate(true),
		setSoundLastSate(true)
	}, [props.soundOfOnSwitcher])

	return (
		<VoiceStyle className={`FlexBox relativeWrapp ${soundState ? "active" : ""}`} onClick={PlayPause}>
			<LottieAnimation animData={soundOnOf} isPlay={!soundState} isReverse={soundState} autoplay={false} loop={false} />

			<audio ref={soundRef} src={'/sound/Verdi.mp3'} preload="auto" controls loop={true} autoPlay={false}>
			</audio>
		</VoiceStyle>
	)
})

export default withUIContext(Voice, ['soundOfOnSwitcher'])
