import React, { memo as Memo, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, CustomLink, Icon, Text, AnimationWrapp } from 'components/common'

//* Config
import { arrayGen } from 'helpers'

//* Styles
import BackToStyle from './style'

const BackTo = Memo((props) => {

    //! States
    const [secObj, setSecObj] = useState()

    //! Refs
    const iconRef = useRef()
    const textRef = useRef()

    useEffect(() => {
        if (
            props.text && iconRef?.current && textRef?.current
        ) {
            setSecObj([
                arrayGen([iconRef.current, textRef.current])
            ])
        }

    }, [iconRef, textRef, props.text, props.selectedLang])



    return props.text && (
        <BackToStyle className={props.className}>
            <AnimationWrapp state={secObj} >
                <Container>
                    <CustomLink className="back-btn overflowHidden" url={props.link}>
                        <span className="otherElementAnimation" ref={iconRef}>
                            <Icon className="icon-arrowRight " />
                        </span>
                        <Text ref={textRef} tag={'p'} className={'otherElementAnimation pM NotoSansRegular uppercase'} text={props.text} />
                    </CustomLink>
                </Container>
            </AnimationWrapp>
        </BackToStyle>
    )
})

export default withLanguageContext(BackTo, ['selectedLang'])