import styled from 'styled-components'


const GalleryItemStyle = styled.div.attrs(props => ({ className: props.className }))`
    width: 100%;
    position: relative;
    
    &:not(:last-child){
        margin-bottom: var(--sp28x);
    }

    &:nth-child(odd){
        align-self: flex-start;
    }
    &:nth-child(even){
        align-self: flex-end;
    }
    
    .images {
		position: relative;
        display: flex;
        justify-content: center;
		
		.imageCont img {
			object-fit: contain;
		}

		.load-img {
			position:absolute;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			transform: scale(0.65);
			animation: loadAnimRotate 1.333s infinite cubic-bezier(0.48, 0.04, 0.52, 0.96);
		}

		@keyframes loadAnimRotate {
			0% {
				transform: scale(0.45) rotate(-7deg) translateY(0px);
			}
			
			25% {
				transform: scale(0.45) translateY(-40px);
			}
			
			50% {
				transform: scale(0.45) rotate(7deg) translateY(0px);
			}
			
			75% {
				transform: scale(0.45) translateY(-40px);
			}

			100% {
				transform: scale(0.45) rotate(-7deg);
			}
		}
	}

    .gal-img {
        
        & > span {
            transition: var(--trTime);
            clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
        }
        
        img {
            object-fit: contain;
            transition: var(--trTime);
        }

        &.active {
            & > span {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                transition: all 1s;
            }
        }
    }
    
    &.portrait {
        max-width: var(--sp73x);
        
        .gal-img {
            width: calc(100% - var(--sp4x));
            padding-top: calc(155.5% - var(--sp4x));
        }
    }
    
    &.landscape {
        max-width: var(--sp123x);

        .gal-img {
            width: calc(100% - var(--sp4x));
            padding-top: calc(63% - var(--sp4x));
        }
    }
    
    &.square {
        max-width: var(--sp98x);

        .gal-img {
            width: calc(100% - var(--sp4x));
            padding-top: calc(100% - var(--sp4x));
        }
    }

    &.active {
        .info {
            .title,
            .year {
                transform:translateY(0);
            }
        }

        .cat-info {
            .category {
                transform:translateY(0);
            }

            .more-info-btn {
                transform: scale(1);
            }
        }
    }

    .info {
        margin:var(--sp4x) 0 var(--sp3x);
        overflow: hidden;

        .title,
        .year {
            transform:translateY(120%);
            transition: var(--trTime);
        }
    }
    
    .cat-info {
        overflow: hidden;

        .category {
            transform:translateY(120%);
            transition: var(--trTime);
        }

        .more-info-btn {
            padding:var(--sp1-5x) var(--sp2x);
            border:1px solid var(--textColor);
            border-radius:var(--sp5x);
            cursor: pointer;
            transition: var(--trTime);
            transform: scale(0);
            white-space: nowrap;
            
            i {
                font-size: var(--sp3x);
                margin-right:var(--spSize);
            }
        }
    }

    .more-info {
        position: absolute;
        top:calc(100% + var(--sp4x));
        left:0;
        width:100%;
        padding:var(--sp5x) var(--sp8x);
        border:1px solid var(--textColor);
        border-radius: var(--sp15x);
        backdrop-filter: blur(30px);
        z-index: 2;
        opacity: 0;
        pointer-events:none;
        transition:var(--trTime);
        
        &.active {
            opacity: 1;
            pointer-events:all;
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}){
        &:not(:last-child){
            margin-bottom: var(--sp25x);
        }

        &.portrait {
            max-width: var(--sp53x);
            
            .gal-img {
                width: calc(100% - var(--sp4x));
                padding-top: calc(155.5% - var(--sp4x));
            }
        }
        
        &.landscape {
            max-width: var(--sp91x);

            .gal-img {
                width: calc(100% - var(--sp4x));
                padding-top: calc(63% - var(--sp4x));
            }
        }
        
        &.square {
            max-width: var(--sp72x);

            .gal-img {
                width: calc(100% - var(--sp4x));
                padding-top: calc(100% - var(--sp4x));
            }
        }

        .info {
            margin:var(--sp4x) 0 var(--sp2x);
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}){
        &:not(:last-child){
            margin-bottom: var(--sp17-5x);
        }
        
        &.portrait {
            max-width: var(--sp50x);
            
            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(155.5% - var(--sp3x));
            }
        }
        
        &.landscape {
            max-width: var(--sp77x);

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(63% - var(--sp3x));
            }
        }
        
        &.square {
            max-width: var(--sp62x);

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(100% - var(--sp3x));
            }
        }

        .info {
            margin:var(--sp3x) 0 var(--sp2x);
        }

        .more-info {
            padding:var(--sp4x) var(--sp7x);
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}){
        &:not(:last-child){
            margin-bottom: var(--sp15x);
        }
        
        &.portrait {
            max-width: var(--sp47x);
            
            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(155.5% - var(--sp3x));
            }
        }
        
        &.landscape {
            max-width: var(--sp63x);

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(63% - var(--sp3x));
            }
        }
        
        &.square {
            max-width: var(--sp53x);

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(100% - var(--sp3x));
            }
        }

        .info {
            margin:var(--sp3x) 0 var(--sp2x);
        }

        .more-info {
            padding:var(--sp4x) var(--sp7x);
            top:calc(100% + var(--sp3x))
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}){
        &:not(:last-child){
            margin-bottom: var(--sp12-5x);
        }
        
        &.portrait {
            max-width: unset;
            
            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(125% - var(--sp3x));
            }
        }
        
        &.landscape {
            max-width: unset;

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(63% - var(--sp3x));
            }
        }
        
        &.square {
            max-width: unset;

            .gal-img {
                width: calc(100% - var(--sp3x));
                padding-top: calc(100% - var(--sp3x));
            }
        }

        .info {
            margin:var(--sp3x) 0 var(--sp2x);
        }

        .more-info {
            padding:var(--sp4x) var(--sp7x);
            top:calc(100% + var(--sp2x))
        }
    }
`

export default GalleryItemStyle