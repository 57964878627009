const homeTheme = {
    textColor: "#DCF0CF",
    backColor: "#3B3E39",
    logo: '/images/svg/logos/logoHome.svg'
}

const aboutMuseumTheme = {
    textColor: "#BCC1E2",
    backColor: "#183334",
    logo: '/images/svg/logos/logoAbout.svg'
}
const contactTheme = {
    textColor: "#FFE9B2",
    backColor: "#657E73",
    logo: '/images/svg/logos/logoContact.svg'
}

const donateTheme = {
    textColor: "#242465",
    backColor: "#E9E8EB",
    logo: '/images/svg/logos/logoSupport.svg'
}
const eventsTheme = {
    textColor: "#3B3E39",
    backColor: "#DBE2D5",
    logo: '/images/svg/logos/logoEvents.svg'
}

const exhibitionsTheme = {
    textColor: "#F8C6A4",
    backColor: "#60352F",
    logo: '/images/svg/logos/logoExhibitions.svg'
}

const filmsTheme = {
    textColor: "#FFD8B5",
    backColor: "#63262C",
    logo: '/images/svg/logos/logoFilms.svg'
}
const galleryTheme = {
    textColor: "#B25915",
    backColor: "#F2F0E4",
    logo: '/images/svg/logos/logoGallery.svg'
}

const newsTheme = {
    textColor: "#1F1F1F",
    backColor: "#D0D0D0",
    logo: '/images/svg/logos/logoNews.svg'
}
const shopTheme = {
    textColor: "#FAF4EF",
    backColor: "#6E211A",
    logo: '/images/svg/logos/logoShop.svg'
}

const checkoutTheme = {
    textColor: "#6E211A",
    backColor: "#FAF4EF",
    logo: '/images/svg/logos/logoCheckout.svg'
}

const supportUsTheme = {
    textColor: "#242465",
    backColor: "#E9E8EB",
    logo: '/images/svg/logos/logoSupport.svg'
}

const ticketsTheme = {
    textColor: "#3C5531",
    backColor: "#F4F1E7",
    logo: '/images/svg/logos/logoTickets.svg'
}

const termsTheme = {
    textColor: "#D8ADB7",
    backColor: "#364C57",
    logo: '/images/svg/logos/logoTerms.svg'
}

export default {
    homeTheme,
    ticketsTheme,
    supportUsTheme,
    shopTheme,
    newsTheme,
    galleryTheme,
    filmsTheme,
    exhibitionsTheme,
    eventsTheme,
    donateTheme,
    contactTheme,
    aboutMuseumTheme,
    checkoutTheme,
    termsTheme
}

export const mediaQuery = {
    errColor: '#B10202',

    DesktopSizeL: "2560px",
    DesktopSizeM: "1920px",
    DesktopSizeS: "1440px",
    DesktopSizeXS: "1280px",
    LaptopSize: "1024px",
    TabletSize: "768px",
    MobileSize: "601px",

    DesktopSizeLMin: "2559px",
    DesktopSizeMMin: "1919px",
    DesktopSizeSMin: "1439px",
    DesktopSizeXSMin: "1279px",
    LaptopSizeMin: "1023px",
    TabletSizeMin: "767px",
    MobileSizeMin: "600px",
}