import { memo as Memo, useMemo, useEffect, useState } from 'react'

//* HOC's
import { useRouter } from 'next/router'
import { withUIContext, withCartContext } from 'context'

//* Components
import { NextSeo } from 'next-seo'
import { GlobalRotateDeviceMessage } from 'components/common'

//* Styles
import PageStyle from './style'

const Page = Memo(({ meta, toggleCart, ...props }) => {

    const [orientationSt, setOrientation] = useState(0)
    //! Router
    const router = useRouter()

    //! URL
    const url = useMemo(() => process.env.host + (router.locale !== 'en' ? '/' + router.locale : '') + router.asPath, [router])

    //! Description
    const description = useMemo(() =>
        meta?.description || 'Parajanov meta description'
        , [meta?.description])

    //! Title
    const title = useMemo(() => meta?.title || 'Parajanov', [meta?.title])

    useEffect(() => {
        window.addEventListener("orientationchange", function () {
            setOrientation(window.orientation)
        }, false)
        return () => window.removeEventListener("orientationchange", function () {
            setOrientation(window.orientation)
        }, false)
    }, [])

    useEffect(() => {
        toggleCart(false)
        window.scrollTo(0, 0);
    }, [router.asPath])

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                canonical={url}
                type='website'
                additionalMetaTags={[
                    {
                        name: 'keywords',
                        content: meta?.keywords,
                    },
                ]}
                openGraph={{
                    url: url,
                    title: title,
                    description: description,
                    images: [{ url: meta?.image || process.env.host + '/images/map.png', alt: title }],
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />

            <PageStyle className={props.className}>{
                orientationSt === 90 && props.winWidth < 1024 ?
                    <GlobalRotateDeviceMessage />
                    :
                    props.children
            }</PageStyle>
        </>
    )
})

export default withUIContext(withCartContext(Page, ['toggleCart']), ['screenSizes', 'winWidth'])

