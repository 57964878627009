import React, { memo as Memo, useCallback, useEffect, useState, useMemo } from 'react'
import { withRouter, useRouter } from 'next/router'

//* HOCs
import { withUIContext } from 'context'

//* Components
import MenuItem from './menuItem'
import { Translate, Icon, LanguageSwitcher } from 'components/common'

const Menu = Memo((props) => {
    const [subMenuOpenIndex, setSubMenuOpenIndex] = useState('')

    const router = useRouter()

    const getParentClassFromActiveChild = useCallback(
        (child) => {
            return props.router.asPath === child.path + '/' ? 'active' : ''
        },
        [props.router.asPath]
    )

    const OpenSubMenu = (k) => {
        if (k !== subMenuOpenIndex) {
            setSubMenuOpenIndex(k)
        } else {
            setSubMenuOpenIndex(false)
        }
    }

    useEffect(() => {
        setSubMenuOpenIndex(false)
    }, [router])

    useEffect(() => {
        props.scrollDir == 'hide' && setSubMenuOpenIndex(false)
    }, [props.scrollDir])

    const menuItems = (arr, t, c = '') => {
        return arr.map((o, k) => {
            const parentClass = o ? getParentClassFromActiveChild(o) : ''
            if (o.subMenu != undefined) {
                return (
                    <li className={c + ' ' + parentClass} key={k} onClick={() => OpenSubMenu(k)}>
                        {o.subMenu[0].subMenuName ? (
                            <>
                                <span
                                    className={`cursorPointer titleMenu  ${props.titleClass ? props.titleClass : ''} ${props.winWidth < 1024 ? '' : subMenuOpenIndex === k ? 'active' : ''
                                        }`}
                                >
                                    <Translate val={o.subMenu[0].subMenuName} />
                                </span>

                                {props.winWidth >= 1280 ? <Icon className={'icon-arrowTop'} /> : ''}
                            </>
                        ) : (
                            ''
                        )}
                        {props.winWidth >= 1280 ? (
                            <ul className={`${props.subContClass || ''} ${subMenuOpenIndex === k ? 'active' : ''}`}>
                                {o.subMenu.map((item, key) => {
                                    const parentClass = item ? getParentClassFromActiveChild(item) : ''
                                    if (key > 0 && item.externalPath != undefined) {
                                        return (
                                            <MenuItem
                                                item={item}
                                                key={key}
                                                classes={props.subItemClass + ' ' + parentClass}
                                                type={'default'}
                                                path={item.externalPath}
                                            />
                                        )
                                    } else if (key > 0) {
                                        return (
                                            <MenuItem
                                                item={item}
                                                key={key}
                                                classes={props.subItemClass + ' ' + parentClass}
                                                type={props.type}
                                                path={item.path}
                                            />
                                        )
                                    }
                                })}
                            </ul>
                        ) : (
                            <ul className={props.subContClass || ''}>
                                {o.subMenu.map((item, key) => {
                                    const parentClass = item ? getParentClassFromActiveChild(item) : ''
                                    if (key > 0 && item.externalPath != undefined) {
                                        return (
                                            <MenuItem
                                                item={item}
                                                key={key}
                                                classes={props.subItemClass + ' ' + parentClass}
                                                type={'default'}
                                                path={item.externalPath}
                                            />
                                        )
                                    } else if (key > 0) {
                                        return (
                                            <MenuItem
                                                item={item}
                                                key={key}
                                                classes={(item.itemClass ? item.itemClass : props.subItemClass) + ' ' + parentClass}
                                                type={props.type}
                                                path={item.path}
                                            />
                                        )
                                    }
                                })}
                            </ul>
                        )}
                    </li>
                )
            } else {
                return <MenuItem item={o} key={k} classes={c + ' ' + parentClass} type={props.type} path={o.path} />
            }
        })
    }

    const closeBlock = useMemo(() => {
        if (props.winWidth >= 1280) {
            return (
                <li className={`backClose ${subMenuOpenIndex === 0 || subMenuOpenIndex === 1 ? 'active' : ''}`} onClick={() => OpenSubMenu(false)} />
            )
        } else if (props.winWidth < 1280 && props.winWidth >= 1024) {
            return <li className={`backClose ${props.openClass ? 'active' : ''}`} onClick={props.onClick} />
        } else {
            ; ('')
        }
    }, [props.winWidth, props.openClass, subMenuOpenIndex])

    return (
        <ul className={props.contClass || ''}>
            {menuItems(props.items, props.type, props.itemClass)}
            {closeBlock}
            {props.headerMenu ? props.winWidth >= 768 ? '' : <li><LanguageSwitcher className='languageSwitcher' /></li> : ''}
        </ul>
    )
})

export default withRouter(withUIContext(Menu, ['screenSizes', 'winWidth']))
