import React, { memo as Memo, useMemo, useState, useEffect } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withDataContext } from 'context'

//*Component
import { Text, Icon } from 'components/common'

//* Style
import GlobalInfoPopupStyle from './style'

const GlobalInfoPopup = Memo(({ global, selectedLang, ...props }) => {

    //! States
    const [checked, setChecked] = useState(false)
    const [opened, setOpened] = useState(false)

    //! Page Data
    const pageData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    useEffect(() => {
        if (checked && !opened) {
            setOpened(true)
        } else if (!checked && opened) {
            setOpened(false)
        }
    }, [checked, opened, props.winWidth])


    return (
        <GlobalInfoPopupStyle>

            <div className='socIconWrapp FlexBox alignMiddle' onClick={() => setChecked(!checked)}>
                <Icon className={'icon-i'} />
            </div>

            {
                pageData ?
                    <div className={`popupInfoWrapp ${checked ? "active" : ""}`}>
                        <div className="borderAnima" />
                        <div className="infoText">
                            <div className="popupInfoItem FlexBox ">
                                <Icon className={'icon-datepicker pM'} />
                                <div className="FlexBox alignMiddle">
                                    <Text tag="span" className={'pXS NotoSansRegular'} text={"openEveryday"} />
                                </div>
                            </div>
                            <div className="popupInfoItem FlexBox ">
                                <Icon className={'icon-clock pM'} />
                                <div className="FlexBox alignMiddle">

                                    <Text tag="span" className={'pXS NotoSansRegular'} text={"openingHours"} />
                                </div>
                            </div>
                            <div className="popupInfoItem FlexBox ">
                                <Icon className={'icon-pin pM'} />
                                <div className="FlexBox alignMiddle">

                                    <Text tag="span" className={'pXS NotoSansRegular'} text={pageData.address} />
                                </div>
                            </div>
                            <div className="popupInfoItem FlexBox ">
                                <Icon className={'icon-phone pM'} />
                                <div className="FlexBox alignMiddle">
                                    <a href={`tel:${pageData.phones}`} target="_blank" rel="noopener noreferrer" className="pXS NotoSansRegular">{pageData.phones}</a>
                                </div>
                            </div>
                        </div>

                    </div>

                    :
                    ""
            }
        </GlobalInfoPopupStyle>
    )
})

export default withLanguageContext(withUIContext(withDataContext(GlobalInfoPopup,
    ['global']),
    ['winWidth']),
    ['translate', 'selectedLang'])