import _ from 'lodash'

export function imagePathToWebP(src) {
	const pos = src.lastIndexOf('.'),
		image = src.includes('images.ctfassets') ? src + '?fm=webp' : src.substr(0, pos < 0 ? src.length : pos) + '.webp'
	return image
}

export const isWebPSupport = () => {
	const elem = typeof document === 'object' ? document.createElement('canvas') : {}
	if (!!(elem.getContext && elem.getContext('2d'))) {
		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
	}
	return false
}

export function removePrefix(str) {
	return str.replace('mm_', '')
}

export const queryStringToObject = (url) => {
	return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value()
}

export const objectToQueryString = (obj) => {
	if (obj) {
		const results = []
		_.forOwn(obj, (value, key) => {
			if (Array.isArray(value)) {
				_.forOwn(value, (value) => {
					results.push(`${key}=${value}`)
				})
			} else {
				results.push(`${key}=${value}`)
			}
		})
		return results.join('&')
	}
	return ''
}

export const routerToPathAndQuery = (router, key, val, multiple, clearKeyItems) => {
	let newVal = val?.toString()

	if (multiple) {
		let paramsArr = getParamsByKey(router, key)
		if (paramsArr) {
			paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal]
			newVal = paramsArr.join(',')
		}
	}

	const queryObj = { ...router.query, [key]: newVal }
	delete queryObj['params']

	if (clearKeyItems) {
		delete queryObj[clearKeyItems]
	}

	if (router.query[key] === newVal || !newVal) {
		delete queryObj[key]
	}

	const paramsPath = router.query.params && router.query.params.join('/')
	let pathname = router.pathname
	pathname = pathname.replace('[[...params]]', paramsPath || '')

	return { pathname, query: queryObj }
}

export const getParamsByKey = (router, key) => {
	return router.query[key]?.split(',')
}

export const arrayGen = (x) => {
	let arr = []
	x.forEach((el) => {
		arr.push(el)
	})
	return arr
}

export const arrayGenChild = (x) => {
	let arr = []
	x.forEach((el) => {
		el.childNodes.forEach((el2) => {
			arr.push(el2)
		})
	})
	return arr
}

export const stringToLetters = (text) => {
	let wordLength = text.split(' ')

	return wordLength.map((i, k) => {
		return (
			<span className='word' key={k}>
				{i}
			</span>
		)
	})
}

export const formatNumber = (x, leng) => {
	let str = x && (typeof x === 'string' ? x : x.toString())

	if (!x) {
		return 0
	} else {
		str = str.replace(',', '')
		str = str.replace(/\s/g, '')
		str = new Intl.NumberFormat('de-DE').format(str)
		return str
	}
}

export const pageColor = (router) => {
	let theme

	let rout

	if (router.query?.type) {
		rout = router.query.type
	} else {
		rout = router.asPath.split('/')
		rout = rout[1]
	}

	switch (rout) {
		case 'home':
			theme = 'homeTheme'
			break
		case 'about-museum':
			theme = 'aboutMuseumTheme'
			break
		case 'contact':
			theme = 'contactTheme'
			break
		case 'donate':
			theme = 'donateTheme'
			break
		case 'events':
			theme = 'eventsTheme'
			break
		case 'exhibitions':
			theme = 'exhibitionsTheme'
			break
		case 'films':
			theme = 'filmsTheme'
			break
		case 'gallery':
			theme = 'galleryTheme'
			break
		case 'news':
			theme = 'newsTheme'
			break
		case 'shop':
			theme = 'shopTheme'
			break
		case 'support-us':
			theme = 'supportUsTheme'
			break
		case 'tickets':
			theme = 'ticketsTheme'
			break
		case 'checkout':
			theme = 'checkoutTheme'
			break
		case 'terms-and-conditions':
			theme = 'termsTheme'
			break
		default:
			theme = 'homeTheme'
	}

	return theme
}

//! generate random number between two numbers

export const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
}

export const asyncFunctionDecorator = (asyncFunc) => {
	return asyncFunc().catch((err) => {
		return Promise.reject(err.response)
	})
}

export const getRequestCookies = (ctx, key) => {
	return ctx?.req?.cookies?.[key]
}

export const getCookie = (key) => {
	return Cookie.get(key)
}

export const setCookie = (key, cookie) => {
	Cookie.set(key, cookie)
}

export const removeCookie = (key) => {
	Cookie.remove(key)
}
