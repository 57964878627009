import { memo as Memo ,useEffect,useRef,useState,useMemo} from 'react'
import {Text} from 'components/common'
import { useCountUp } from 'react-countup';

const Number = Memo( props => {
    const numRef = useRef();
    const [first, setfirst] = useState(true)

    const {start} = useCountUp({
        ref:numRef,
        duration:1.5,
        start:0,
        end:props.end,
        startOnMount:true
    })

    useEffect(() => {
        window.addEventListener('scroll',scroll);

        return () => {
            window.removeEventListener('scroll',scroll);
        }
    },[first])

    const scroll = () =>{
    
        if(numRef && numRef?.current.getBoundingClientRect().y < window.innerHeight*0.9 && first){
            setfirst(false)
            start()
        }
    }
    return (
        <Text ref={numRef} tag={props.tag} className={` ${props.className} ${props.font}`}/>
    )
} )

export default Number;