import styled from 'styled-components'

const AnimationWrappStyle = styled.section.attrs({})`
	.word {
		display: inline-block;
		margin-right: calc(var(--h2) / 2.5);
		line-height: 1;
		transform: rotateY( -90deg) translateY(140%); 
		transform-origin: center;
		&:last-child {
			margin-right: 0;
		}
	}

	.singleTitle {
		transform: rotateY( -90deg) translateY(140%); 
		transform-origin: center;
	}

	.description,
	.otherElementAnimation {
		transform: translateY(180%);
		transform-origin: center;
	}
	
	.fromLeft {
		transform: translateX(-150%);
	}
`

export default AnimationWrappStyle
