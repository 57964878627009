import React, { memo as Memo } from 'react'

//* Style
import CircleStyle from './style'

const CircleContainer = Memo((props) => {
    return (
        <CircleStyle className={`filmsWrapp ${props.startAnimation ? "active" : ""}`}>
            {props.children}
        </CircleStyle>
    )
})
export default CircleContainer
