import styled from 'styled-components'

const SingleEventStyle = styled.div`
	--eventOptionsTopPad: var(--sp7x);
	--leftWidth: 18%;
	--rightWidth: 67%;
	--rightPadding: var(--sp36x);
	--titleMarg: var(--sp7x);

	padding: var(--sp5x) var(--containerPadding);
	align-items: center;
	width: 100%;

	.single-event {
		&.active {
			.imageCont {
				clip-path: circle(100% at 50% 50%);
			}
		}
	}

	&:hover {
		.info .right .event-options i {
			opacity: 1;
		}
	}

	&:not(:last-child) {
		border-bottom: 1px solid var(--textColor);
	}

	.info {
		flex: 1;

		.FlexBoxContainer {
			&:not(:last-child) {
				.left,
				.right {
					margin-bottom: var(--titleMarg);
				}
			}
		}

		.left {
			width: var(--leftWidth);
			.category {
				i {
					display: inline-block;
					animation: starIconAnim 10s linear 0s infinite;
					margin-right: calc(var(--h6) / 2);
				}
			}
		}

		.right {
			padding-left: var(--rightPadding);
			width: var(--rightWidth);
			.event-options {
				.single-option {
					&:first-child {
						margin-right: var(--sp9x);
					}

					.single-option-title {
						margin-bottom: var(--sp2x);
					}
				}

				i {
					margin-left: var(--sp15x);
					font-size: var(--sp9x);
					opacity: 0;
					transition: var(--trTime);
				}
			}
		}
	}

	.imageCont {
		width: 25%;
		padding-top: 25%;
		clip-path: circle(0% at 50% 50%);
		transition: all var(--trTime) ease-in-out;
		transition-delay: var(--trTime2);
		border-top-right-radius: 60%;
		border-top-left-radius: 60%;
		overflow: hidden;
	}

	@keyframes starIconAnim {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--eventOptionsTopPad: var(--sp6x);
		--leftWidth: 21%;
		--rightWidth: 76%;
		--titleMarg: var(--sp6x);
		--rightPadding: var(--sp21x);

		padding: var(--sp4x) var(--containerPadding);

		.info {
			.right {
				.event-options {
					.single-option {
						&:first-child {
							margin-right: var(--sp8x);
						}
					}

					i {
						margin-left: var(--sp10x);
						font-size: var(--sp7x);
					}
				}
			}
		}

		.imageCont {
			width: 25.5%;
			padding-top: 25.5%;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--eventOptionsTopPad: var(--sp5x);
		--leftWidth: 25%;
		--rightWidth: 67%;
		--titleMarg: var(--sp5x);
		--rightPadding: var(--sp21x);

		padding: var(--sp3x) var(--containerPadding);

		.info {
			.right {
				.event-options {
					.single-option {
						&:first-child {
							margin-right: var(--sp7x);
						}

						.single-option-title {
							margin-bottom: var(--sp1x);
						}
					}

					i {
						margin-left: var(--sp10x);
						font-size: var(--sp5x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--eventOptionsTopPad: var(--sp3x);
		--leftWidth: 25%;
		--rightWidth: 67%;
		--titleMarg: var(--sp3x);
		--rightPadding: var(--sp3x);

		padding: var(--sp2x) var(--containerPadding);

		.info {
			.right {
				.event-options {
					.single-option {
						&:first-child {
							margin-right: var(--sp6x);
						}

						.single-option-title {
							margin-bottom: var(--sp1x);
						}
					}
				}
			}
		}

		@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.imageCont {
				width: 33%;
				padding-top: 33%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--eventOptionsTopPad: var(--sp3x);
		--leftWidth: 37%;
		--rightWidth: 56%;
		--titleMarg: var(--sp3x);
		--rightPadding: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--eventOptionsTopPad: var(--sp3x);
		--leftWidth: 28%;
		--rightWidth: 64%;
		--titleMarg: var(--sp3x);
		--rightPadding: var(--sp4x);

		padding: var(--sp2x) var(--containerPadding);
		.single-event {
			flex-direction: column-reverse;
		}
		.info {
			.categoryWrap {
				width: 100%;
			}
		}
		.info {
			margin-top: var(--sp2x);
			.right {
				flex: 1;
                padding-left: 0;
				.event-options {
                    margin-top: var(--sp2x);
					.single-option {
						&:first-child {
							margin-right: var(--sp6x);
						}

						.single-option-title {
							margin-bottom: var(--sp1x);
						}
					}
				}
			}
		}

		.imageCont {
			width: 61%;
			padding-top: 61%;
		}
	}
`

export default SingleEventStyle
