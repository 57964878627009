import React, { memo as Memo, useMemo } from 'react'
import Link from 'next/link'

import { withLanguageContext } from 'context'
import { withRouter } from 'next/router'

import LanguageSwitcherStyle from './style'

const LanguageSwitcher = Memo(({ selectLanguage, languages, ...props }) => {

    return (
        <LanguageSwitcherStyle className={` ${props.className ? props.className : ''} FlexBox`}>
            {Object.keys(languages).map((lang, key) => {
                return (
                    <span key={key} >
                        <Link href={props.router.asPath} locale={lang}>
                            <a
                                className={`${props.router.locale === lang ? "NotoSansBold" : "NotoSansRegular"}  pXS capitalize`}
                            >
                                {languages[lang].params.name}
                            </a>
                        </Link>
                        {
                            key != Object.keys(languages).length - 1 ?
                                "/"
                                :
                                ""
                        }
                    </span>
                )
            })}
        </LanguageSwitcherStyle>
    )
})

export default withRouter(withLanguageContext(LanguageSwitcher, ['selectLanguage', 'languages']))
