import React, { memo as Memo, useState, useRef, useEffect } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//*component
import { AnimationWrapp, Text, Image, Container } from 'components/common'

//* Styles
import ImgTextCompStyle from './style'

const ImgTextComp = Memo(
	({ winWidth, winHeight, selectedLang, title, titleSize, description, bigImage, smallImage, typeImage, className, ...props }) => {
		//! Refs
		const contRef = useRef()
		const titleRef = useRef()
		const textRef = useRef()
		const ref = useRef()

		//! States
		const [secObj, setSecObj] = useState()
		const [secTopObj, setSecTopObj] = useState()
		const [startAnimation, setStartAnimation] = useState(false)

		useEffect(() => {
			if (titleRef?.current && contRef?.current) {
				setSecTopObj(contRef.current[0])
				setSecObj({
                    animItems: [titleRef.current.firstChild, textRef.current.firstChild],
                    stateTriggerArray: [titleRef.current]
                })
			}
		}, [titleRef, contRef, selectedLang])

		const StartAnim = (x) => {
			x && !startAnimation ? setStartAnimation(true) : setStartAnimation(false)
		}

		return (
			<AnimationWrapp stateTriggerArray={secObj?.stateTriggerArray} state={secObj?.animItems} sectionTop={secTopObj} sectionAnimationStart={(e) => StartAnim(e)}>
				<ImgTextCompStyle ref={ref} className={typeImage || ''}>
					<Container
						className={`FlexBoxContainer containerPaddTopBottom containerPaddTopBottom overflowHidden ${className || ''}`}
						ref={contRef}
					>
						<div className='bigImageWrapp'>
							<Image
								className={`${props.landscapeImg ? 'landscape' : ''} ${startAnimation ? 'active' : ''}`}
								src={bigImage ? bigImage : '/images/default.jpeg'}
								alt='featured_image'
							/>
						</div>

						<div className='info FlexBoxColumn'>
							<div ref={titleRef} className='margBottomTitle overflowHidden'>
								<Text tag={'h5'} className={`description GHEAMariamRegular ${titleSize || 'h5'}`} text={title} />
							</div>
							<div ref={textRef} className='overflowHidden'>
								<div className='fromLeft' dangerouslySetInnerHTML={{ __html: description }}></div>
							</div>
						</div>
						{smallImage ? (
							<div className='smallImageWrapp FlexBox justifyEnd'>
								<Image
									className={`${props.landscapeImg ? 'landscape' : ''} ${startAnimation ? 'active' : ''}`}
									src={smallImage ? smallImage : '/images/default.jpeg'}
									alt='featured_image'
								/>
							</div>
						) : (
							''
						)}
					</Container>
				</ImgTextCompStyle>
			</AnimationWrapp>
		)
	}
)

export default withLanguageContext(withUIContext(ImgTextComp, ['winWidth', 'winHeight']), ['selectedLang'])
