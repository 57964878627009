import styled from 'styled-components'

const GlobalInfoPopupStyle = styled.div.attrs((props) => '')`
	--paddingTopLeft: var(--sp2-5x);
	--iconSize: var(--sp5-5x);
	--popupPaddingTop: var(--sp13x);
	--popupPaddingLeft: var(--sp6x);
	--popupMinWidth: var(--sp42x);
	--popupInfoMargBottom: var(--sp5x);
	--popupInfoiconMargRight: var(--sp2x);

	position: fixed;
	top: 50%;
	right: var(--sp2x);
	transform: translateY(-50%);
	z-index: 13;

	.socIconWrapp {
		cursor: pointer;
		padding: var(--paddingTopLeft);
		border-radius: var(--sp3x);
		background-color: var(--textColor);
		border: 1px solid var(--backColor);
		i {
			color: var(--backColor);
			font-size: var(--iconSize);
		}
	}

	.popupInfoWrapp {
		position: absolute;
		top: 50%;
		right: 150%;
		pointer-events: none;
		min-width: var(--popupMinWidth);
		transform: translateY(-25%);

		.borderAnima {
			pointer-events: none;
			position: absolute;
			top: 50%;
			left: 50%;
			right: 50%;
			bottom: 50%;
			border-radius: var(--sp25x);
			border: 1px solid var(--textColor);
			border-radius: var(--sp25x);
			transition: all 0.6s ease;
			opacity:0;
			overflow: hidden;
			backdrop-filter: blur(var(--spSize));
			&:after{
				content:"";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: var(--sp25x);
				opacity: 0.9;
				z-index: -1;
			}
		}
		.infoText {
			padding: var(--popupPaddingTop) var(--popupPaddingLeft);
			clip-path: ellipse(0% 0% at 50% 50%);
			transition: clip-path 0.6s ease;
			overflow: hidden;
		}

		.popupInfoItem {
			margin-bottom: var(--popupInfoMargBottom);
			i {
				margin-right: var(--popupInfoiconMargRight);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.active {
			pointer-events: all;
			z-index: 11;
			.infoText {
				clip-path: ellipse(61% 51% at 50% 50%);
			}
			.borderAnima {
				top: 0%;
				left: 0%;
				right: 0%;
				bottom: 0%;
				opacity:1;
				// transition-delay: 0s;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--paddingTopLeft: var(--sp1-75x);
		--iconSize: var(--sp4x);

		--popupPaddingTop: var(--sp11x);
		--popupPaddingLeft: var(--sp5x);
		--popupMinWidth: var(--sp37x);
		--popupInfoMargBottom: var(--sp4x);
		--popupInfoiconMargRight: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--paddingTopLeft: var(--sp1-5x);
		--iconSize: var(--sp3x);

		--popupPaddingTop: var(--sp10x);
		--popupPaddingLeft: var(--sp4x);
		--popupMinWidth: var(--sp35x);
		--popupInfoMargBottom: var(--sp4x);
		--popupInfoiconMargRight: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--paddingTopLeft: var(--sp1-3x);
		--iconSize: var(--sp2-8x);

		--popupPaddingTop: var(--sp9x);
		--popupPaddingLeft: var(--sp3x);
		--popupMinWidth: var(--sp30x);
		--popupInfoMargBottom: var(--sp3x);
		--popupInfoiconMargRight: var(--spSize);
	}
`

export default GlobalInfoPopupStyle
