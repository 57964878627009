import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
    :root{
        --inputPaddingInTop: var(--sp2-5x);
        --inputPaddingInLeft: var(--sp5x);
    }

    //ant select box drop down style

    .ant-select-dropdown{
        z-index:111111;
        border: 1px solid var(--textColor);
        border-radius: var(--sp5x);
        background-color:var(--backColor);
        padding: var(--inputPaddingInTop) var(--inputPaddingInLeft);
        .ant-select-item{
            cursor: pointer;
            min-height: auto;
            font-family: NotoSansRegular;
            font-size: var(--pM);
            line-height: var(--lineHeightXS);
            color: var(--textColor);
            padding:var(--inputPaddingInTop) 0;
            &.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
                font-weight:800;   
                background-color: transparent;
            }
            &:hover{
                font-weight:800;
                background-color: transparent;
            }
        }
    }

    //end

    /* Typography */

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
    }

    * {
        -webkit-font-smoothing: antialiased;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px #fff;
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--textColor) !important;
        -webkit-box-shadow: inset 0 0 0 50px var(--textColor) !important;
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 2px solid black;
        -webkit-text-fill-color: black;
        color: white;
        background-color: transparent;
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    }

    @-webkit-keyframes autofill {
        to {
            background: transparent;
        }
    }

    a {
        text-decoration: none;
        color: var(--textColor);
        &:hover{
            color: var(--textColor);
        }
    }

    div[role=presentation]{
        z-index: 158 !important;
        &+div{
            z-index:111111;
        }
    }

    //Text capitalize

    .uppercase {
        text-transform: uppercase;
    }

    .lowercase {
        text-transform: lowercase;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .underline {
        text-decoration: underline;
    }

    .lineThrough{
        text-decoration: line-through;
    }

    //!Fonts

    .GHEAMariamRegular {
        font-family: GHEAMariamRegular;
    }
    
    .GHEAMariamBold {
        font-family: GHEAMariamBold;
    }

    .NotoSansRegular {
        font-family:NotoSansRegular;
    }

    .NotoSansBold {
        font-family:NotoSansBold;
    }

    //!Font Sizes


    /* h1, h2, h3, h4, h5, h6 ,p {
        color:var(--textColor) !important;
    } */

    .h1L {
        font-size: var(--h1L);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h1,
    h1 {
        font-size: var(--h1);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
        
    }

    .h2,
    h2 {
        font-size: var(--h2);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h3,
    h3 {
        font-size: var(--h3);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h4,
    h4 {
        font-size: var(--h4);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h5,
    h5 {
        font-size: var(--h5);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h6,
    h6 {
        font-size: var(--h6);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .p,p {
        font-size: var(--p);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pL{
        font-size: var(--pL);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pM {
        font-size: var(--pM);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pS {
        font-size: var(--pS);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pXS {
        font-size: var(--pXS);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .lineHeightNormal {
        line-height: var(--lineHeightS);
    }


    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
        :root{
            --inputPaddingInTop: var(--sp2x);
            --inputPaddingInLeft: var(--sp4x);
        }
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
        :root{
            --inputPaddingInTop: var(--sp1-5x);
            --inputPaddingInLeft: var(--sp3x);
        }
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
        :root{
            --inputPaddingInTop: var(--sp1-5x);
            --inputPaddingInLeft: var(--sp3x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
        :root{
            --inputPaddingInTop: var(--sp1-5x);
            --inputPaddingInLeft: var(--sp3x);
        }
	}
`

export default Typography
