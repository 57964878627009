import React, { memo as Memo } from 'react'

//* HOCs
import { withUIContext } from 'context'

//* Components
import { CustomLink, Image } from 'components/common'

//* Styles
import LogoStyle from './style'

const Logo = Memo((props) => {
    return (
        <LogoStyle className={`Logo ${props.className}`}>
            <CustomLink className="FlexBox alignMiddle" url={"/"} content={
                <Image layout="fill" src={props.theme.logo} alt="logo" />
            } />
        </LogoStyle>
    )
})


export default withUIContext(Logo, ['theme'])