import styled from 'styled-components'

const ContainerStyle = styled.section.attrs({})`
	max-width: var(--maxWidth);
	padding-left: var(--containerPadding);
	padding-right: var(--containerPadding);
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	&.reverseColor {
		color:var(--backColor);
		background-color:var(--textColor);
	}
	
	&.containerPaddBottom{
		margin-bottom: var(--containerMarginBottom);
	}
	&.containerPaddTopBottom{
		padding-top: var(--containerMarginBottom);
		padding-bottom: var(--containerMarginBottom);
	}
	&.containerPaddTop{
		margin-top: var(--containerMarginTop);
	}

	&.no-padding {
		padding-left: 0;
		padding-right: 0;
	}

`
export default ContainerStyle
