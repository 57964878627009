import React, { Component, createContext } from 'react'
import { withRouter } from 'next/router'
import Cookie from 'js-cookie'
import { api, asyncFunctionDecorator } from 'helpers'

const CartContext = createContext(null)
export const CartConsumer = CartContext.Consumer

class CartProvider extends Component {
    state = {
        items_quantity: 0,
        items: [],
        productIds: [],
        subtotal: 0,
        shippingPrice: '-',
        total: 0,
        cartOpened: false
    }

    methods = {
        toggleCart: this.toggleCart.bind(this),
        getCart: this.getCart.bind(this),
        addToCart: this.addToCart.bind(this),
        removeCart: this.removeCart.bind(this),
        checkShipping: this.checkShipping.bind(this),
        checkout: this.checkout.bind(this),
        clearCart: this.clearCart.bind(this)
    }

    componentDidMount() {
        this.getCart().then(() => {
            this.updateCheckoutProducts()
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.asPath !== this.props.router.asPath) {
            this.setState({ shippingPrice: '-' })
            this.updateCheckoutProducts()
        }
    }

    updateCheckoutProducts() {
        api.get('get-cart-products', this.props.router.locale, { product_ids: this.state.productIds }).then(({ data }) => {
            const newData = this.state.items.map(item => {
                const product = data.find(prod => item.product.id == prod.id)

                if (product) {
                    return {
                        product: {
                            ...product,
                            size: item.product.size
                        },
                        quantity: item.quantity
                    }
                }
            })

            this.setState({ items: newData }, () => {
                Cookie.set('cart', this.state.items)
                this.calculate()
            })
        })
    }

    toggleCart(x) {
        if (x == false) {
            this.setState({
                cartOpened: false
            })
        } else {
            window.scrollTo(0, 0);
            this.setState({
                cartOpened: !this.state.cartOpened
            })
        }
    }

    getCart() {
        return new Promise(resolve => {
            const cart = Cookie.getJSON('cart')
            if (cart) {
                this.setState({
                    items: cart,
                }, () => {
                    this.calculate()
                })
            }
            resolve()
        })
    }

    addToCart(productId, quantity, price, size, name, image) {
        const newItem = this.state.items.find((i) => (i.product.id === productId && i.product.size === size))
        let items = []

        if (!newItem) {
            items = this.state.items.concat({
                quantity: quantity,
                product: {
                    id: productId,
                    price: price,
                    size: size,
                    name: name,
                    image: image
                },
            })
        } else {
            items = this.state.items.map((item) =>
                (item.product.id === productId && item.product.size === size)
                    ? Object.assign({}, item, {
                        quantity: quantity,
                        product: {
                            ...item.product,
                            price: price,
                            size: item.product.size,
                            name: name,
                            image: image
                        },
                    }) : item
            )
        }

        this.setState({ items: items }, () => {
            Cookie.set('cart', this.state.items)
            this.calculate()
        })
    }

    removeCart(productId, size) {
        const items = [...this.state.items]
        const index = items.findIndex((i) => i.product.id === productId && i.product.size === size)
        items.splice(index, 1)
        this.setState({ items }, () => {
            Cookie.set('cart', this.state.items)
            this.calculate()
        })
    }

    calculate() {
        let total = 0,
            subtotal = 0,
            items_quantity = 0,
            productIds = []

        this.state.items.map((item) => {
            productIds.push(item.product.id)
            total += item.product.price * item.quantity
            subtotal += item.product.price * item.quantity
            items_quantity += item.quantity
        })

        this.setState({
            total,
            subtotal,
            items_quantity,
            productIds
        })
    }

    checkShipping(countryId) {
        return asyncFunctionDecorator(() =>
            api.post('check-shipping', { countryId: countryId, products: this.reformatProducts() })
                .then(({ subtotal, shippingPrice, total }) => {
                    this.setState({
                        ...this.state,
                        subtotal,
                        shippingPrice,
                        total
                    })
                })
        )
    }

    checkout(data) {
        return asyncFunctionDecorator(() => api.post('checkout', { ...data, products: this.reformatProducts() }))
    }


    reformatProducts() {
        return this.state.items.map(item => {
            return {
                id: item.product.id,
                price: item.product.price,
                qty: item.quantity,
                size: item.product.size
            }
        })
    }

    clearCart() {
        this.setState({
            items_quantity: 0,
            items: [],
            productIds: [],
            subtotal: 0,
            shippingPrice: '-',
            total: 0,
            cartOpened: false
        })
    }

    render() {
        return (
            <CartContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </CartContext.Provider>
        )
    }
}

export default withRouter(CartProvider)
