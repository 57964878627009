// const modulesFiles = require.context('./', true, /\.json$/);
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//     const moduleName = (modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')).replace(/-([a-z])/g, g => g[1].toUpperCase());
//     modules[moduleName] = modulesFiles(modulePath);
//     return modules;
// }, {});

import en from './en.json'
import hy from './hy.json'
import ru from './ru.json'

const modules = { en, hy, ru }

module.exports = modules
