import styled from 'styled-components'

const Header = styled.header.attrs((props) => ({}))`
	--padTopBot: var(--sp6-5x);
	--menuRightMarg: var(--sp10x);
	--lastItemLeftMarg: var(--sp7x);

	color: var(--textColor);
	position: sticky;
	top: -500px;
	transition: all var(--trTime2) ease-in-out;
	z-index: 100;

	&.show {
		top: 0;
	}

	&.hide {
		top: -500px;
	}
	&.static{
		position: relative;
		top: 0px;
	}

	> section {
		padding-top: var(--padTopBot);
		padding-bottom: var(--padTopBot);
		border-bottom: 1px solid var(--textColor);
		backdrop-filter: blur(var(--spSize));
		position: relative;
		&:after{
			content:"";
			position: absolute;
			z-index: -1;
			top:0;
			bottom:0;
			left:0;
			right:0;
			background-color: var(--backColor);
			opacity: 0.3;
		}
	}

	.rightSection > * {
		&:not(:last-child) {
			margin-right: var(--menuRightMarg);
		}
		&:nth-last-child(2) {
			margin-right: var(--lastItemLeftMarg);
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--padTopBot: var(--sp4-5x);
		--lastItemLeftMarg: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--menuRightMarg: var(--sp5x);
		--lastItemLeftMarg: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) {
		--padTopBot: var(--sp2-5x);
		--menuRightMarg: var(--sp4x);
		--lastItemLeftMarg: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--padTopBot: var(--sp2-5x);
		--menuRightMarg: var(--sp5x);
		--lastItemLeftMarg: var(--sp3x);

		.rightSection > * {
			&:nth-last-child(3) {
				margin-right: var(--lastItemLeftMarg);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--menuRightMarg: var(--sp2-5x);
		--lastItemLeftMarg: var(--sp2-5x);
	}
`

export default Header
