import styled from 'styled-components'

const CircleStyle = styled.div`
	--titleMarg: var(--sp7x);
	--textWrapPadd: calc(-1 * var(--sp8x));
	--filmWrapBorder: var(--sp2x);
	--filmsWrappHeight: 25.5% 0;

	position: relative;
	padding: var(--filmsWrappHeight);
	margin: 0 auto;

	.textWrapLeft,
	.textWrapRight {
		position: absolute;
		top: 50%;
		white-space: nowrap;
	}
	.textWrapLeft {
		left: 50%;
		transform: translate(-50%, -100%) rotate(0deg);
		transform-origin: center;
	}
	.textWrapRight {
		right: 50%;
		transform: translate(50%, -0%) rotate(180deg);
		transform-origin: center;
	}
	.videoWrapper {
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform-origin: center;
		.homepageFilms {
			clip-path: ellipse(0% 0% at 50% 50%);
			position: absolute;
			top: var(--filmWrapBorder);
			left: var(--filmWrapBorder);
			width: calc(100% - (var(--filmWrapBorder) * 2));
			height: calc(100% - (var(--filmWrapBorder) * 2));
			object-fit: cover;
			transform: rotate(90deg);
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			bottom: 50%;
			right: 50%;
			border: 1px solid var(--textColor);
			border-radius: 70%;
			transform: rotate(90deg);
			transform-origin: center;
		}
	}
	&.active {
		.textWrapLeft,
		.textWrapRight {
			transition: all var(--trTime2) ease;
		}
		.videoWrapper {
			.homepageFilms {
				transition-delay: var(--trTime);
				/* transition:  all var(--trTime3) ease ,transform var(--trTime3) cubic-bezier(0.72,-0.67,0.37,1.67); */
				transition:  all var(--trTime3) ease ,transform var(--trTime3) cubic-bezier(0.47, 1.64, 0.61, 0.88)   ;
				clip-path: ellipse(50% 50% at 50% 50%);
				transform: rotate(0deg);
			}
			&:after {
				transition-delay: var(--trTime);
				top: 1px;
				left: 1px;
				bottom: 1px;
				right: 1px;
				/* transition:  all var(--trTime2) ease ,transform var(--trTime3) cubic-bezier(0.72,-0.67,0.37,1.67); */
				transition:  all var(--trTime3) ease ,transform var(--trTime3) cubic-bezier(0.47, 1.64, 0.61, 0.88)   ;
				transform: rotate(0deg) scale(1);
			}
		}
		.textWrapLeft {
			left: var(--textWrapPadd);
			transform: translate(-50%, -50%) rotate(-90deg);
		}
		.textWrapRight {
			right: var(--textWrapPadd);
			transform: translate(50%, -50%) rotate(90deg);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--titleMarg: var(--sp6x);
		--textWrapPadd: calc(-1 * var(--sp6x));
		--filmWrapBorder: var(--sp1-6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--titleMarg: var(--sp5x);
		--textWrapPadd: calc(-1 * var(--sp5x));
		--filmWrapBorder: var(--sp1-5x);
		--filmsWrappHeight: 25% 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--titleMarg: var(--sp4x);
		--textWrapPadd: calc(-1 * var(--sp4x));
		--filmWrapBorder: var(--sp1-3x);
		--filmsWrappHeight: 25% 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		--titleMarg: var(--sp3x);
		--textWrapPadd: calc(-1 * var(--sp2x));
		--filmWrapBorder: var(--spSize);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--filmsWrappHeight: 27% 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--filmsWrappHeight: 29% 0;
	}
`
export default CircleStyle
