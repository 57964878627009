import styled from 'styled-components'

const LoaderStyle = styled.section.attrs('')`
	&.hide {
		.LottieWrapp {
			&:first-child {
				opacity: 0;
			}
			&:last-child {
				opacity: 1;
			}
		}
	}

	.LottieWrapp {
		&:first-child{
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-color:var(--backColor);
				z-index: -1;
			}
		}
		&:last-child {
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100vw;
			height: 100vh;
			left: 0;
		}
		path{
			fill:var(--textColor);
		}
	}
`
export default LoaderStyle
