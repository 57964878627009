import styled from 'styled-components'


const styleAnimationWrapp = styled.div.attrs('')`
    &:not(:last-child){
        border-bottom: 1px solid var(--textColor);
    }
    &:last-child{
        padding-bottom: var(--containerMarginBottom);
    }
`

export default styleAnimationWrapp