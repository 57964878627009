import React, { memo as Memo, useEffect, useState, useMemo, useRef } from 'react'

//* HOCs
import { withUIContext, withCartContext } from 'context'
import { useRouter } from 'next/router'

//*Component
import Cart from './HeaderComponent/Cart'
import Voice from './HeaderComponent/Voice'
import MenuComponent from './HeaderComponent/MenuComponent'
import Burger from './HeaderComponent/Burger'

import { LanguageSwitcher, Logo, Container, ButtonTickets } from 'components/common'

//* Styles
import Header from './style'

//* Helpers

const AppHeader = Memo(({ toggleCart, isSafari, ...props }) => {
	//! States
	const [checked, setChecked] = useState(false)
	const [opened, setOpened] = useState(false)
	const [scrollDir, setScrollDir] = useState('show')
	const oldScroll = useRef(null)

	const router = useRouter()

	useEffect(() => {
		if (checked && !opened) {
			setOpened(true)
			oldScroll.current = window.scrollY
			document.querySelector('html').classList.add('modal-open')
		} else if (!checked && opened) {
			setOpened(false)
			document.querySelector('html').classList.remove('modal-open')
			window.scrollTo({ top: oldScroll.current })
			oldScroll.current = null
		}
	}, [checked, opened, router, router.asPath, props.winWidth, oldScroll.current])

	useEffect(() => {
		setChecked(false)
	}, [router])

	const handleClick = () => {
		setChecked(!checked)
	}

	useEffect(() => {
		if (router.asPath.split('/')[1] !== 'products') {
			const threshold = 0
			let lastScrollY = window.pageYOffset

			const onScroll = () => {
				if (router.pathname != '/films') {
					const scrollY = window.pageYOffset
					setScrollDir(scrollY > lastScrollY ? 'hide' : 'show')
					lastScrollY = scrollY > 0 ? scrollY : 0
				} else {
					setScrollDir('static')
				}
			}

			window.addEventListener('scroll', onScroll)

			return () => window.removeEventListener('scroll', onScroll)
		}
	}, [router])

	const burger = useMemo(() => {
		if (props.winWidth < 1280) {
			return <Burger checked={checked} onClick={handleClick} />
		}
	}, [checked, props.winWidth])

	const languageSwitcher = useMemo(() => {
		if (props.winWidth >= 768) {
			return <LanguageSwitcher />
		}
	}, [props.winWidth])

	const menuDesc = useMemo(() => {
		if (props.winWidth >= 1280) {
			return <MenuComponent opened={checked} scrollDirection={scrollDir} />
		}
	}, [props.winWidth, checked, scrollDir])

	const menuMob = useMemo(() => {
		if (props.winWidth < 1280) {
			return <MenuComponent opened={checked} onClick={handleClick} />
		}
	}, [props.winWidth, checked, scrollDir])

	return (
		<Header className={`header ${scrollDir}`}>
			<Container className={'FlexBoxContainer spaceBetween alignMiddle'}>
				<div className='FlexBoxContainer leftSection alignMiddle'>
					<Logo className={'headerLogo'} />
					{menuDesc}
				</div>
				<div className='FlexBoxContainer rightSection alignMiddle'>
					{languageSwitcher}
					<Cart />
					<ButtonTickets />
					{!isSafari && <Voice />}

					{burger}
				</div>
			</Container>
			{menuMob}
		</Header>
	)
})

export default withUIContext(withCartContext(AppHeader, ['toggleCart']), ['screenSizes', 'winWidth'])
