import React, { memo as Memo, useMemo } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Menu } from 'components/common'

//* JSON's
import config from 'helpers/_config'

//style
import MenuStyle from './style'

const MenuComponent = Memo(({ winWidth, ...props }) => {
    //! Routes from Config
    const routes = useMemo(() => config.routes, [])

    //! Big Menu Object
    const menuObj = useMemo(
        () => ({
            contClass: 'menu-items-cont FlexBox',
            itemClass: 'menu-item pXS uppercase NotoSansRegular FlexBox alignMiddle',
            titleClass: 'FlexBox alignMiddle',
            subContClass: 'menu-item-sub-cont FlexBox alignMiddle',
            subItemClass: 'menu-item-sub pXS uppercase NotoSansRegular',
            items: [
                {
                    subMenu: [
                        {
                            subMenuName: 'SergeyParajanov',
                        },
                        // {
                        // 	name: 'AboutParajanov',
                        // 	externalPath: "http://parajanovmuseum.am/hy/sergei-parajanov/bio",
                        // },
                        {
                            name: routes.gallery.name,
                            path: routes.gallery.path,
                        },
                        {
                            name: routes.films.name,
                            path: routes.films.path,
                        }
                    ]
                },
                {
                    subMenu: [
                        {
                            subMenuName: 'Museum',
                        },
                        {
                            name: routes.aboutMuseum.name,
                            path: routes.aboutMuseum.path,
                        },
                        {
                            name: routes.exhibitions.name,
                            path: routes.exhibitions.path,
                        },
                        {
                            name: routes.news.name,
                            path: routes.news.path,
                        }
                    ]
                },
                {
                    name: routes.supportUs.name,
                    path: routes.supportUs.path,
                },
                {
                    name: routes.shop.name,
                    path: routes.shop.path,
                },
                {
                    name: routes.contact.name,
                    path: routes.contact.path,
                },
            ],
        }),
        [routes]
    )

    //! Big Menu Mobile Object
    const menuObjMobile = useMemo(
        () => ({
            contClass: 'menu-items-cont FlexBoxContainer',
            itemClass: 'menu-item pXS',
            titleClass: 'uppercase GHEAMariamBold',
            subContClass: 'menu-item-sub-cont',
            subItemClass: 'menu-item-sub pXS NotoSansRegular',
            items: [
                {
                    subMenu: [
                        {
                            subMenuName: 'SergeyParajanov',
                        },
                        // {
                        // 	name: 'AboutParajanov',
                        // 	externalPath: "http://parajanovmuseum.am/hy/sergei-parajanov/bio",
                        // },
                        {
                            name: routes.gallery.name,
                            path: routes.gallery.path,
                        },
                        {
                            name: routes.films.name,
                            path: routes.films.path,
                        }
                    ]
                },
                {
                    subMenu: [
                        {
                            subMenuName: 'Museum',
                        },
                        {
                            name: routes.aboutMuseum.name,
                            path: routes.aboutMuseum.path,
                        },
                        {
                            name: routes.exhibitions.name,
                            path: routes.exhibitions.path,
                        },
                        {
                            name: routes.news.name,
                            path: routes.news.path,
                        }
                    ]
                },
                {
                    subMenu: [
                        {

                        },
                        {
                            name: routes.supportUs.name,
                            path: routes.supportUs.path,
                            itemClass: 'menu-item pXS uppercase GHEAMariamBold',
                        },
                        {
                            name: routes.shop.name,
                            path: routes.shop.path,
                            itemClass: 'menu-item pXS uppercase GHEAMariamBold',
                        },
                        {
                            name: routes.contact.name,
                            path: routes.contact.path,
                            itemClass: 'menu-item pXS uppercase GHEAMariamBold',
                        },
                    ]
                }
            ]
        }),
        [routes]
    )

    const menu = useMemo(() => {
        if (winWidth >= 1280) {
            return (
                <Menu type={'nav'} onClick={props.onClick} {...menuObj} scrollDir={props.scrollDirection} />
            )
        }
        else {
            return (
                <Menu type={'nav'} onClick={props.onClick} {...menuObjMobile} openClass={props.opened} headerMenu={true} />
            )
        }

    }, [routes, winWidth, props.opened, menuObj, menuObjMobile, props.scrollDirection])

    return (
        <MenuStyle className={props.opened ? "open" : ""}>
            {menu}
        </MenuStyle>
    )
})
export default withUIContext(MenuComponent, ['screenSizes', 'winWidth'])
