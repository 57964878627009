import styled from 'styled-components'


const ImageStyle = styled.div.attrs('')`
  &.imageCont {
    position: relative;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
`
export default ImageStyle
