import React, { memo as Memo } from 'react'
import { withUIContext } from 'context'

import { LottieAnimation } from 'components/common'

import LoaderStart from 'lottieAnimation/preloaderStart.json'
import LoaderEnd from 'lottieAnimation/preloaderEnd.json'

//* Style's
import LoaderStyle from './style'

const Loader = Memo((props) => {

    return props.preloader === false ?
        null :
        (
            <LoaderStyle style={{
                position: 'fixed',
                top: '0',
                width: '100vw',
                height: '100vh',
                left: '0',
                zIndex: '112',
                overflow: 'hidden'
            }}
                className={`FlexBox alignMiddle alignCenter ${props.preloaderHide ? "hide" : ""}`}>
                <LottieAnimation animData={LoaderStart} autoplay={true} loop={true} />
                <LottieAnimation animData={LoaderEnd} isPlay={props.preloaderHide} autoplay={false} loop={false} />
            </LoaderStyle >

        )
})

export default withUIContext(Loader, ['preloaderHide', 'preloader'])
