import styled from 'styled-components'

const MenuStyle = styled.div.attrs((props) => ({}))`
	--subLeftRightPadding: var(--containerPadding);
	--subTopPadding: var(--sp3x);
	--iconPaddingLeft: var(--sp2x);
	--iconSize: var(--sp2-5x);
	--menuItem: var(--sp25x);

	.menu-items-cont {
		.menu-item,
		.menu-item-sub {
			margin-right: var(--menuRightMarg);
			&:last-child {
				margin-right: 0;
			}
			&.active {
				text-decoration: underline;
			}
		}
	}

	.titleMenu {
		color: var(--textColor);
		& ~ i {
			cursor: pointer;
		}
		&.active {
			text-decoration: underline;
			& ~ {
				i {
					transform: rotateX(180deg);
				}
			}
		}
	}

	.backClose.active {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: -3;
	}

	.menu-item-sub-cont {
		background-color: var(--backColor);
		padding: var(--subTopPadding) var(--subLeftRightPadding);
		border-top: 1px solid var(--textColor);
		border-bottom: 1px solid var(--textColor);
		transition: all var(--trTime2) ease-in-out;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		&.active {
			opacity: 1;
			visibility: visible;
		}
	}

	i {
		transition: transform var(--trTime2) ease-in-out;
		margin-left: var(--iconPaddingLeft);
		font-size: var(--iconSize);
		color: var(--textColor);
	}

	.menu-item {
		max-width: var(--menuItem);
	}

	.Link {
		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--iconPaddingLeft: var(--sp1-75x);
		--iconSize: var(--sp2x);
		--menuItem: var(--sp14x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--iconPaddingLeft: var(--spSize);
		--iconSize: var(--sp2x);
		--menuItem: var(--sp17x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--subTopPadding: var(--sp2x);
		--iconPaddingLeft: var(--sp1-5x);
		--iconSize: var(--sp1-75x);
		--menuItem: var(--sp15x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--subTopPadding: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		.titleMenu,
		.menu-item {
			font-size: var(--sp2x);
			a {
				font-size: var(--sp2x);
			}
		}

		overflow: hidden;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
		position: absolute;
		width: 100vw;
		top: -100vh;
		right: 0;
		z-index: -1;
		border-bottom: 1px solid var(--textColor);
		padding: var(--subTopPadding) var(--containerPadding);
		background-color: var(--backColor);
		transition: all var(--trTime2) ease-in-out;
		overflow: auto;
		&.open {
			top: 100%;
		}

		.menu-item-sub-cont {
			position: static;
		}

		.menu-items-cont {
			width: 100%;
			height: auto;
			.menu-item,
			.menu-item-sub {
				margin-bottom: var(--sp3x);
				margin-right: 0;
				&:last-child {
					margin-bottom: 0;
				}
			}
			> .menu-item {
				flex: 0 0 33.333%;
			}
		}
		.titleMenu {
			display: block;
			margin-bottom: var(--sp3x);
		}
		.menu-item {
			max-width: 100%;
		}

		.menu-item-sub-cont {
			padding: 0;
			border-top: 0;
			border-bottom: 0;
			z-index: 1;
    		opacity: 1;
			visibility: visible;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--subTopPadding: var(--sp5x);
		height: calc(100vh - var(--sp10x));

		.menu-items-cont {
			> .menu-item {
				margin-bottom: var(--sp5x);
				flex: 0 0 100%;
			}
		}

		.languageSwitcher {
			align-items: flex-end;
		}
	}
`

export default MenuStyle
