export default {
  routes: {
    home: {
      path: '/',
      slug: 'home',
      name: 'home',
      autoload: true,
    },
    supportUs: {
      path: '/support-us',
      slug: 'donation',
      name: 'SupportUs',
      autoload: true,
    },
    donate: {
      path: '/donate',
      slug: 'donation-ind',
      name: 'Donate',
      autoload: true,
    },
    shop: {
      path: '/shop',
      slug: 'products',
      name: 'Shop',
      autoload: true,
    },
    singleProducts: {
      path: '/shop/:slug',
      slug: 'products',
      name: 'shop',
      singleType: 'singleProducts',
      autoload: true,
    },
    contact: {
      path: '/contact',
      slug: 'contact',
      name: 'Contact',
      autoload: false,
    },
    checkout: {
      path: '/checkout',
      slug: 'checkout',
      name: 'Checkout',
      autoload: false,
    },
    tickets: {
      path: '/tickets',
      slug: 'tickets',
      name: 'Tickets',
      autoload: true,
    },
    exhibitions: {
      path: '/exhibitions',
      slug: 'exhibitions',
      name: 'Exhibitions',
      autoload: true,
    },
    news: {
      path: '/news',
      slug: 'news',
      name: 'News',
      autoload: true,
      postType: {
        slug: 'news',
        name: 'news',
        queryParamKeys: ['page'],
      }
    },
    singleNews: {
      path: '/news/:slug',
      slug: 'news',
      name: 'News',
      singleType: 'singleNews',
      autoload: true,
    },
    singleEvents: {
      path: '/events/:slug',
      slug: 'events',
      name: 'events',
      singleType: 'singleEvents',
      autoload: true,
    },
    gallery: {
      path: '/gallery',
      slug: 'galleries',
      name: 'Gallery',
      autoload: true,
      postType: {
        slug: 'get-galleries',
        name: 'gallery',
        queryParamKeys: ['catId', 'page'],
      }
    },
    films: {
      path: '/films',
      slug: 'films',
      name: 'Films',
      autoload: true,
    },
    aboutMuseum: {
      path: '/about-museum',
      slug: 'about-museum',
      name: 'AboutMuseum',
      autoload: true,
    },
    terms: {
      path: '/terms-and-conditions',
      slug: 'terms',
      name: 'TermsAndConditions',
      autoload: true,
    }
  },
}
