import { memo as Memo, useRef, useCallback } from 'react'

//* Components
import { Input } from 'antd'

const FormInput = Memo(({ disableKeys, ...props }) => {
    //! Refs
    const ref = useRef()
    //! On Focus
    const onFocus = useCallback(() => {
        if (ref.current?.props?.type === 'number') {
            ref.current.input.addEventListener('wheel', onWheel)
        }
    }, [ref])

    //! On Blur
    const onBlur = useCallback(() => {
        if (ref.current?.props?.type === 'number') {
            ref.current.input.removeEventListener('wheel', onWheel)
        }

    }, [ref])

    const onKeyDown = useCallback(e => {
        disableKeys?.includes(e.key) && e.preventDefault()
    }, [disableKeys])

    //! On Wheel
    const onWheel = useCallback((e) => e.preventDefault(), [ref])

    return <Input onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyDown} ref={ref} {...props} step={props.step && '0.1'} />
})

export default FormInput
