import styled from 'styled-components'

const SoundOnOffStyle = styled.div`
	--arrowMarginTop: var(--sp9x);
	--arrowIconSize: var(--sp9x);

	--welcomeTextMarginTop: var(--sp9x);
	--welcomeTextWidth: 24%;

	--contentWidth: 19%;

	--starMarginLeft: var(--sp4x);

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 110;
	background: var(--backColor);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.arrow {
		margin-top: var(--arrowMarginTop);
		cursor: pointer;
		i{
			display: inline-block;
			animation: arrowAnim 3s linear 0s infinite;
			&:before {
				font-size: var(--arrowIconSize);
			}
		}

	}

	.welcomeText {
		text-align: center;
		width: var(--welcomeTextWidth);
		margin-top: var(--welcomeTextMarginTop);
	}

	.contentWrap {
		position: relative;
		width: var(--contentWidth);
		.fork {
			width: 62%;
			padding-top: 8.6%;
			margin-bottom: var(--sp4x);
			.image {
				object-fit: contain;
			}
		}
		.eye {
			position: absolute;
			width: 27.6%;
			padding-top: 24.7%;
			right: 0;
			top: 50%;
			transform: translateY(-70%);
			z-index: 3;

			.image {
				object-fit: contain;
			}
		}
		.eyeBg {
			z-index: 2;
		}
		.eyeBall {
			z-index: 2;
			position: absolute;
			width: 20%;
			padding-top: 20%;
			right: 0;
			top: 50%;
			transform: translate(-15%, -72%);
			animation: animateEyeBall 5s 0s infinite;
		}
		.ear {
			position: absolute;
			width: 11.5%;
			padding-top: 19.6%;
			right: 108%;
			top: 28%;
			transform-origin: 80% 60%;
			transition: var(--trTime2);
			.image {
				object-fit: contain;
			}
			&.active {
				transform: rotate(-25deg);
				animation: cubic-bezier(0, 0.5, 1, 0.5);
			}
		}
		.museum {
			position: relative;
			justify-content: flex-end;
			width: 100%;
			.imageCont {
				animation: noseAnim 2s 0s infinite;
				width: 14%;
				padding-top: 14%;
				.image {
					object-fit: contain;
				}
			}
			.museumText {
				margin-left: var(--spSize);
			}
			i {
				margin-left: var(--starMarginLeft);
				animation: starIconAnim 10s linear 0s infinite;

				&:before {
					font-size: var(--sp3-5x);
				}
			}
		}
	}

	@keyframes starIconAnim {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes arrowAnim {
		0% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(20px);
		}
		100% {
			transform: translateX(0);
		}
	}

	@keyframes noseAnim {
		0% {
			transform: translateY(-6px) rotate(-1deg);
		}
		50% {
			transform: translateY(10px) rotate(25deg);
		}
		100% {
			transform: translateY(-6px) rotate(-1deg);
		}
	}

	@keyframes animateEyeBall {
		0% {
			transform: translate(-15%, -72%);
		}
		25% {
			transform: translate(-5%, -60%);
		}
		50% {
			transform: translate(-15%, -72%);
		}
		65% {
			transform: translate(-40%, -68%);
		}
		85% {
			transform: translate(0%, -72%);
		}
		100% {
			transform: translate(-15%, -72%);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--arrowMarginTop: var(--sp8x);
		--arrowIconSize: var(--sp7x);

		--welcomeTextMarginTop: var(--sp8x);
		--welcomeTextWidth: 28%;

		--contentWidth: 20%;

		--starMarginLeft: var(--sp2-5x);
		.contentWrap {
			.eye {
				right: -6%;
			}
			.eyeBall {
				right: -6%;
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--arrowMarginTop: var(--sp7x);
		--arrowIconSize: var(--sp6x);

		--welcomeTextMarginTop: var(--sp7x);
		--welcomeTextWidth: 33%;

		--contentWidth: 22%;

		--starMarginLeft: var(--sp1-5x);

		.contentWrap {
			.fork {
				margin-bottom: var(--sp3x);
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--arrowMarginTop: var(--sp6x);
		--arrowIconSize: var(--sp5x);

		--welcomeTextMarginTop: var(--sp6x);
		--welcomeTextWidth: 33%;

		--contentWidth: 23%;

		--starMarginLeft: var(--sp1-5x);
		.contentWrap {
			.fork {
				margin-bottom: var(--sp3x);
			}
			.eye {
				right: 6%;
			}
			.eyeBall {
				right: 6%;
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--arrowMarginTop: var(--sp5x);
		--arrowIconSize: var(--sp5x);

		--welcomeTextMarginTop: var(--sp5x);
		--welcomeTextWidth: 42%;

		--contentWidth: 27%;

		--starMarginLeft: var(--sp1-5x);
		.contentWrap {
			.fork {
				width: 60%;
				margin-bottom: var(--sp3x);
			}
			.eye {
				right: 13%;
				top: 55%;
			}
			.eyeBall {
				right: 13%;
				top: 55%;
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--arrowMarginTop: var(--sp5x);
		--arrowIconSize: var(--sp5x);

		--welcomeTextMarginTop: var(--sp5x);
		--welcomeTextWidth: 56%;

		--contentWidth: 34%;

		--starMarginLeft: var(--sp1-5x);

		@keyframes noseAnim {
			0% {
				transform: translateY(-3px) rotate(-1deg);
			}
			50% {
				transform: translateY(5px) rotate(15deg);
			}
			100% {
				transform: translateY(-3px) rotate(-1deg);
			}
		}
		.contentWrap {
			.eye {
				top: 55%;
				width: 35%;
				right: 3%;
			}
			.eyeBall {
				top: 55%;
				width: 25%;
				right: 3%;
			}
			.fork {
				margin-bottom: var(--sp3x);
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--arrowMarginTop: var(--sp5x);
		--arrowIconSize: var(--sp5x);

		--welcomeTextMarginTop: var(--sp5x);
		--welcomeTextWidth: 90%;

		--contentWidth: 50%;

		--starMarginLeft: var(--sp1-5x);

		.museum {
			margin-top: var(--spSize);
		}

		@keyframes noseAnim {
			0% {
				transform: translateY(-2px) rotate(-1deg);
			}
			50% {
				transform: translateY(4px) rotate(15deg);
			}
			100% {
				transform: translateY(-2px) rotate(-1deg);
			}
		}
		.contentWrap {
			.fork {
				margin-bottom: var(--sp3x);
			}
		}
	}
`

export default SoundOnOffStyle
