import React, { memo as Memo } from 'react'

//* Config
import { config } from 'helpers'

//* Components
import { Translate, CustomLink } from 'components/common'

//* Styles
import TicketsStyle from './style'

const ButtonTickets = Memo((props) => {
    return (
        <TicketsStyle>
            <CustomLink url={config.routes.tickets.path} className={' backColor FlexBoxContainer'} content={
                <>
                    <div className="ticketsLeft FlexBox alignMiddle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 90.902 49.553">
                            <path id="Path_12258" data-name="Path 12258" d="M90.9,221.631h-.625V216.84H90.9v-3.195h-.625v-4.791H90.9v-3.2h-.625v-4.791H90.9v-3.195h-.625v-4.792H90.9v-3.194h-.625V184.9H90.9v-1.84c-.143,0-.286,0-.431-.013a5.416,5.416,0,0,1-4.971-4.971H-.006v49.552H85.641a5.411,5.411,0,0,1,5.255-4.1Z" transform="translate(0.006 -178.07)" />
                        </svg>
                        <span className="pXS uppercase NotoSansRegular">
                            <Translate val={'Tickets'} />
                        </span>
                    </div>
                    <div className="ticketsRight FlexBox alignMiddle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 19.103 49.553">
                            <path id="Path_12259" data-name="Path 12259" d="M96.294,178.071a5.414,5.414,0,0,1-5.4,4.984v1.84h.624v4.792H90.9v3.194h.624v4.792H90.9v3.195h.624v4.791H90.9v3.2h.624v4.791H90.9v3.195h.624v4.791H90.9v1.888a5.383,5.383,0,0,1,5.254,4.1H110V178.071Z" transform="translate(-90.896 -178.07)" />
                        </svg>
                    </div>
                </>
            } />
        </TicketsStyle>
    )
})


export default ButtonTickets