import React, { forwardRef, useRef, useImperativeHandle, useState } from 'react'
import Image from 'next/image'
import ImageStyle from './style'

const CustomImage = forwardRef((props, ref) => {
    const imgRef = useRef()
    const [activeState, setActiveState] = useState('');

    useImperativeHandle(ref, () => [imgRef.current], [])

    return (
        <ImageStyle onMouseEnter={() => setActiveState('activeImage')} onMouseLeave={() => setActiveState('')} className={`imageCont ${props.className ? props.className : ''} ${activeState}`} ref={imgRef}>
            <Image priority={props.priority} onLoad={props.onLoad} layout='fill' unoptimized={props.src.includes('.svg')} src={props.src} alt={props.alt || 'image'} className='image' />
        </ImageStyle>
    )
})

export default CustomImage
