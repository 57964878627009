import React, { memo as Memo, useCallback, useState } from 'react'
import { withRouter } from 'next/router'

import { withUIContext} from 'context'

//* Components
import Nav from './types/nav'
import Default from './types/default'
import { Translate } from 'components/common'

const MenuItem = Memo(props => {
    const [isActive, setIsActive] = useState(false)

    const checkType = useCallback(() => {
        const type = props.type;

        if (type === "nav") {
            return (
                <Nav className={props.classes} path={props.path} data={props.item.name}>
                    <Translate val={props.item.name} />
                </Nav>
            )
        }
        else {
            return (
                <Default path={props.path}>
                    <Translate val={props.item.name} />
                </Default>
            )
        }
    }, [props.type, props.path, props.item, props.onMouseMove, props.onMouseOut, props.svg, props.classes , props.checked])

    const onClick = useCallback(() => {
        if (props.winWidth < props.screenSizes.screenL) {
            props.children && setIsActive(!isActive)
        }
        props.onClick && props.onClick()
    }, [props.winWidth, props.onClick, props.children, props.screenSizes.screenL, isActive])

    return (
        <li className={`${props.classes} ${isActive ? 'active' : ''}`}
            onClick={onClick}
        >
            {checkType()}
            {props.children}
        </li>
    )
})

export default withRouter(withUIContext(MenuItem, ['screenSizes', 'winWidth']))
