import React, { forwardRef } from 'react'
import { CartConsumer } from '../providers/CartProvider'
import { getContext } from 'helpers'

const withCartContext = (ConnectedComponent, properties = []) => {
  return forwardRef((props, ref) => {
    return (
      <CartConsumer>
        {context => {
          let contextProps = properties.length ? getContext(context, properties) : {}
          return <ConnectedComponent {...contextProps} {...props} ref={ref}/>
        }}
      </CartConsumer>
    )
  })
}

export default withCartContext
