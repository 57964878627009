import styled from 'styled-components'

const GlobalRotateDeviceMessageStyle = styled.div.attrs((props) => '')`
	max-width: 500px;
	margin: 0 auto;

	.imageCont {
		padding: 11%;
		width: 43%;
		margin-left: auto;
		margin-right: auto;
	}
	.icon-lock {
		margin-bottom: var(--sp2x);
		display: inline-block;
		line-height: normal;
	}
`

export default GlobalRotateDeviceMessageStyle
