import styled from 'styled-components'

const DonateStyle = styled.div.attrs()`
	--sectionFormWidth: 53%;
	--totalWrappPad: var(--sp4x);
	--iconSize: var(--sp16x);

	> section {
		&:nth-child(2) {
			width: var(--sectionFormWidth);
			.containerPaddTop {
				border-right: 1px solid var(--textColor);
			}
		}
		&:nth-child(3) {
			width: calc(100% - var(--sectionFormWidth));
			.description {
				max-width: 80%;
			}
		}
	}

	.totalWrapp {
		width: 100%;
		margin-top: var(--titleMarg);
		.line {
			transform: translateX(-100%);
			width: 100%;
			border-top: 1px solid var(--textColor);
			padding-top: var(--totalWrappPad);
		}
	}

	.margBottomTitle {
		width: 100%;
	}

	.icon-star2 {
		display: inline-block;
		font-size: var(--iconSize);
		animation: starIconAnim 10s linear 0s infinite;
	}

	@keyframes starIconAnim {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--iconSize: var(--sp14x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--sectionFormWidth: 60%;
		--totalWrappPad: var(--sp3x);
		--iconSize: var(--sp12x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeXS}) {
		--sectionFormWidth: 60%;
		--iconSize: var(--sp9x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) {
		> section {
			&:nth-child(3) {
				.description {
					max-width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		--iconSize: var(--sp8x);

		.FormRow .FormCol.FormCol-50 {
			max-width: 100%;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--sectionFormWidth: 100%;
		--totalWrappPad: var(--sp2x);
		--iconSize: var(--sp7x);

		> section {
			&:nth-child(2) {
				.containerPaddTop {
					border-right: 0;
				}
			}
			&:nth-child(3) {
				width:100%;
			}
		}
	}
`
export default DonateStyle
