import React, { Component, createContext } from 'react'
import { withRouter } from 'next/router'
import withLanguageContext from '../consumerHOC/LanguageConsumer'

import { getData, getPageParams, api, asyncFunctionDecorator } from 'helpers'

const DataContext = createContext(null)
export const DataConsumer = DataContext.Consumer

class DataProvider extends Component {

    state = {
        ...this.props.newData,
        loading: false
    }

    methods = {
        donate: this.donate.bind(this),
        contact: this.contact.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const lang = this.props.router.locale || this.props.selectedLang

        if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
            // this.setState({
            //     loading: true,
            //     prevPageParams: getPageParams({ lang: prevProps.router.locale, router: prevProps.router })
            // })

            getData({
                lang,
                state: this.state,
                router: this.props.router,
                // prevRouter: prevProps.router
            }).then((res) => {
                this.setState({ ...res })
            })
        }
    }

    //! Donate
    donate(data) {
        return asyncFunctionDecorator(() => api.post('donate', data, this.props.router.locale))
    }

    //! Contact
    contact(data) {
        return asyncFunctionDecorator(() => api.post('contact-us', data, this.props.router.locale))
    }


    render() {
        return (
            <DataContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

export default withRouter(withLanguageContext(DataProvider, ['selectedLang']))