import React, { memo as Memo, useEffect, useMemo, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

//* HOC's
import { withUIContext } from 'context'

import AnimationWrappStyle from './style'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const AnimationWrapp = Memo(({ startPoint, otherStartPoint, animationDelay, animationDuration, animationStagger, trigerEl, sectionAnimationStart, stateTriggerArray, ...props }) => {

    //! ref 
    const timeObj = useRef({})

    useEffect(() => {

        if (props.soundOfOnSwitcher && props?.state?.length > 0 && props.state !== null) {
            props.state.forEach((el, i) => {

                if (el) {
                    timeObj.current[i] = new gsap.timeline({ paused: true })

                    if (timeObj.current[i]) {
                        timeObj.current[i].to(el, {
                            duration: animationDuration ? animationDuration : 0.6,
                            delay: animationDelay ? animationDelay : 0,
                            stagger: animationStagger ? animationStagger : 0.09,
                            ease: 'power4.out',
                            y: 0,
                            rotateY: 0,
                            scale: 1,
                            x: 0,
                            opacity: 1,
                        });


                        ScrollTrigger.create({
                            trigger: (stateTriggerArray && stateTriggerArray?.[i]) ? stateTriggerArray[i] : (trigerEl || el),
                            start: startPoint || 'top bottom',
                            onLeaveBack: () => timeObj.current[i].reverse(),
                            onEnter: () => timeObj.current[i].play(),
                        })

                    }
                }

            })
        }
    }, [props.state, props.soundOfOnSwitcher, stateTriggerArray])

    useEffect(() => {
        if (props.sectionTop != undefined && props.sectionTop !== null && props.soundOfOnSwitcher) {
            ScrollTrigger.create({
                trigger: props.sectionTop,
                start: otherStartPoint || 'top bottom',
                onEnter: () => sectionAnimationStart(true),
                onLeaveBack: () => sectionAnimationStart(false)
            })
        }

    }, [props.sectionTop, props.soundOfOnSwitcher])


    useEffect(() => {
        return () => {
            ScrollTrigger.getAll().forEach(t => t.kill());

            if (timeObj.current.length > 0) {
                timeObj.current.forEach((el, i) => {
                    if (el) {
                        timeObj.current[i].kill(el)
                    }
                })

            }
        }
    }, [])

    return <AnimationWrappStyle>{props.children}</AnimationWrappStyle>
})

export default withUIContext(AnimationWrapp, ['soundOfOnSwitcher'])
