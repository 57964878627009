import styled from 'styled-components'

const TicketsStyle = styled.div.attrs((props) => ({}))`
	--ticketsPadd: var(--sp3x);
	--width: var(--sp24x);

	width: var(--width);
	&:hover {
		.ticketsLeft {
			transform: rotate(-15deg);
		}
		.ticketsRight {
			transform: rotate(20deg);
		}
	}
	.ticketsLeft {
		transition: all var(--trTime) ease-in-out;
		transform-origin: bottom;
		position: relative;
		flex: 0 0 82.5%;
		svg {
			fill: var(--textColor);
		}
		span {
			position: absolute;
			line-height: normal;
			color: var(--backColor);
			left: 50%;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
		}
	}

	.ticketsRight {
		transition: all var(--trTime) ease-in-out;
		transform-origin: bottom;
		flex: 0 0 17.5%;
		svg {
			fill: var(--textColor);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--width: var(--sp18x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--width: var(--sp14x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--width: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--width: var(--sp11x);
		&:hover {
			.ticketsLeft {
				transform: rotate(0);
			}
			.ticketsRight {
				transform: rotate(0);
			}
		}
	}
`

export default TicketsStyle
