import React, { memo as Memo, useEffect } from 'react'

//* HOC's
import { withUIContext } from 'context'

//*Component
import { Text, Icon, Image, Container } from 'components/common'

//* Style
import GlobalRotateDeviceMessageStyle from './style'

const GlobalRotateDeviceMessage = Memo((props) => {

    useEffect(() => {
        // props.checkLoadedImage(true)
    }, [])
    return (
        <Container className='containerPaddTopBottom'>
            <GlobalRotateDeviceMessageStyle className={'textAlignCenter'}>
                <Image src={'/images/LoadImgs/2.png'} alt={'Angel Image'} className="margBottomTitle" />
                <Text tag="h6" className={'h6 GHEAMariamRegular margBottomTitle'} text={"Please rotate your device"} />

                <Icon className={'icon-lock h5'} />
                <Text className={'pL NotoSansRegular'} text={"If screen doesn't rotate, make sure your screen orientation is unlocked"} />

            </GlobalRotateDeviceMessageStyle>
        </Container>
    )
})

export default withUIContext(GlobalRotateDeviceMessage, ['checkLoadedImage'])