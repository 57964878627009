import styled from 'styled-components'


const LogoStyle = styled.div`

&.Logo{
    --headerLogoSize:var(--sp21x);
    --footerLogoSize:var(--sp30x);
    --logoMargRight:var(--sp30x);

    a{
        width: 100%;
    }
    img{
        width:100% !important;
        height: auto;
    }
    .imageCont {
        padding-top: 52%;
        width: 100%;
    }
    &:hover{
        color:currentColor;
    }
    &.headerLogo{
        width:var(--headerLogoSize);
        margin-right:var(--logoMargRight);
    }
    &.footerLogo{
        width:var(--footerLogoSize);
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeM}) {
        --headerLogoSize:var(--sp16x);
        --footerLogoSize:var(--sp22x);
        --logoMargRight:var(--sp15x);

    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeS}) {
        --headerLogoSize:var(--sp12x);
        --footerLogoSize:var(--sp18x);
        --logoMargRight:var(--sp14x);

    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${props => props.theme.mediaQuery.TabletSize}) {
        --headerLogoSize:var(--sp11x);
        --footerLogoSize:var(--sp14x);
        --logoMargRight:var(--sp8x);

    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {
        --logoMargRight:var(--sp2x);
        --headerLogoSize:var(--sp9x);
    }
}

`

export default LogoStyle