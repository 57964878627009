import styled from 'styled-components'


const CartStyle = styled.div.attrs((props) => ({}))`
    & > div {
        display: inline-block;
        cursor: pointer;
    }
    
    .icon-cart{
        font-size: var(--sp2-5x);
        margin-bottom: var(--spSize);
        color:var(--textColor);
    }
`

export default CartStyle