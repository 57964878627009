import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
	.rc-virtual-list-scrollbar-thumb {
		background: transparent!important;
        border: 1px solid var(--textColor)!important;
        border-radius: 6px!important;
	}

    .ant-empty-image {
        display: none;
    }

    body {
        width: 100%;
        margin: 0;
        padding: 0;
        color: var(--textColor);
        font-family: NotoSansRegular;
        line-height: var(--lineHeight);
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        position: relative;
        background-color:var(--backColor);

        .minHeight{
            min-height: 89vh;
        }

        main {
            z-index: 1;
            position: relative;
            --titleMarg: var(--sp7x);

            .margBottomTitle {
                margin-bottom: var(--titleMarg);
            }

            &.about-msm{
                overflow-x: hidden;
            }

            &.contact{
                display: flex;
	            flex-direction: column;
                >section{
                    flex-grow: 1;
                    display: flex;
	                flex-direction: column;
                }
            }
            
            @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {
                --titleMarg: var(--sp6x);	
            }

            @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
                --titleMarg: var(--sp5x);
            }

            @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
                --titleMarg: var(--sp4x);
            }

            @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) {
                --titleMarg: var(--sp3x);
            }
        }

        #root {
            overflow-x: hidden;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            overflow:hidden;
            max-height:100vh;
        }
    }

    .modal-open, .modal-open > body {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        height:100vh;
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    // flex box

    .FlexBoxContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .FlexBoxColumn {
        display: flex;
        flex-direction: column;
    }

    .FlexBox {
        display: flex;
    }

    .alignMiddle {
        align-items: center;
    }

    .alignCenter {
        justify-content: center;
    }

    .justifyEnd {
        justify-content: flex-end;
    }
    
    .alignEnd{
        align-items: flex-end;
    }

    .spaceBetween {
        justify-content: space-between;
    }


    .cursorPointer{
        cursor:pointer;
    }
    .cursorDefault{
        cursor:default;
    }

    .textAlignCenter {
        text-align:center;
    }

    .textAlignRight {
        text-align:right;
    }

    //!Colors

    .backColor{
        color: var(--backColor);
    }

    .textColor{
        color: var(--textColor);
    }

    //relaative

    .relativeWrapp{
        position: relative;
    }

    .overflowHidden{
        overflow:hidden;
    }

    //disabled links

    a {
        &.disabled {
            pointer-events: none;
        }
    }
`

export default HelperClass
