import styled from 'styled-components'

const Footer = styled.footer.attrs((props) => ({}))`
	--footeerBootomPadd: var(--sp7x);
	--TicketsPadd: var(--sp9x);
	--starSize: var(--sp4x);
	--starPaddTop: var(--sp4x);
	--starPaddLeft: var(--sp2-5x);
	--footerTopPadd: var(--sp9x);
	--menuItemWrapPadd: var(--sp5x);
	--menuItemPadd: var(--sp7-5x);
	--menuItemPaddRight: var(--sp5x);
	--contactItemPadd: var(--sp4x);
	--nftButtonPadding: var(--sp3x) var(--sp6x);
	--nftButtonMarginButton: var(--sp7x);

	border-top: 1px solid var(--textColor);
	position: relative;
	background-color: var(--backColor);
	z-index: 2;

	.icon-star {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		font-size: var(--starSize);
		padding: var(--starPaddTop) var(--starPaddLeft);

		&:after {
			content: '';
			position: absolute;
			top: -1px;
			left: 0;
			right: 0;
			height: 50%;
			border-bottom: 0;
			display: block;
			border-top: 1px solid var(--textColor);
			border-left: 1px solid var(--textColor);
			border-right: 1px solid var(--textColor);
			border-top-left-radius: 70px;
			border-top-right-radius: 70px;
			background-color: var(--backColor);
			z-index: -1;
		}
	}

	.footerTitleWrap {
		border-bottom: 1px solid var(--textColor);
		padding: var(--footerTopPadd) 0;

		.menuTitle {
			flex: 0 0 15%;
			max-width: 15%;
			padding-right: var(--menuItemPaddRight);
			&:last-child {
				display: flex;
				justify-content: flex-end;
				padding-right: 0;
				padding-left: 0;
			}
			&:nth-child(3) {
				padding-right: 0;
			}
		}
	}

	.footerMenuItemsWrap {
		padding: var(--menuItemWrapPadd) 0 var(--footerTopPadd);
		.menuItem {
			flex: 0 0 15%;
			max-width: 15%;
			padding-right: var(--menuItemPaddRight);

			.menu-cont {
				.menu-item {
					margin-bottom: var(--menuItemPadd);
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&:last-child {
				display: flex;
				justify-content: flex-end;
				padding-right: 0;
				padding-left: 0;
			}
			&:nth-child(3) {
				padding-right: 0;
			}

			.contactItem {
				display: block;
				margin-bottom: var(--contactItemPadd);
				&:last-child {
					margin-bottom: 0;
				}
			}

			.socWrap {
				display: flex;
				flex-direction: column;

				> .FlexBox {
					justify-content: flex-end;
					margin-bottom: var(--menuItemPadd);
				}

				.nft-button {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: var(--nftButtonPadding);
					margin-bottom: var(--nftButtonMarginButton);
					border: 1px solid var(--textColor);
					border-radius: 100%;
					margin-top: auto;
					line-height: 1.1;
				}
			}
		}
	}

	.TicketsWrapp {
		flex: 0 0 100%;
		width: 100%;
		padding-top: var(--TicketsPadd);
		padding-bottom: var(--TicketsPadd);
	}

	.footerBottom {
		border-top: 1px solid var(--textColor);
		padding-top: var(--footeerBootomPadd);
		padding-bottom: var(--footeerBootomPadd);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--footeerBootomPadd: var(--sp6x);
		--TicketsPadd: var(--sp7x);
		--footerTopPadd: var(--sp7x);

		--starSize: var(--sp3-5x);
		--starPaddTop: var(--sp3-5x);
		--starPaddLeft: var(--sp2x);
		--menuItemWrapPadd: var(--sp4x);
		--menuItemPadd: var(--sp5x);
		--contactItemPadd: var(--sp3x);
		--nftButtonPadding: var(--sp2x) var(--sp5x);
		--nftButtonMarginButton: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--footeerBootomPadd: var(--sp5x);
		--TicketsPadd: var(--sp6x);
		--footerTopPadd: var(--sp6x);

		--starSize: var(--sp3-5x);
		--starPaddTop: var(--sp3-5x);
		--starPaddLeft: var(--sp2x);
		--menuItemWrapPadd: var(--sp3x);
		--menuItemPadd: var(--sp4x);
		--contactItemPadd: var(--sp3x);
		--nftButtonPadding: var(--sp2x) var(--sp4x);
		--nftButtonMarginButton: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--footeerBootomPadd: var(--sp4x);
		--TicketsPadd: var(--sp5x);
		--footerTopPadd: var(--sp5x);

		--starSize: var(--sp3-5x);
		--starPaddTop: var(--sp3-5x);
		--starPaddLeft: var(--sp2x);
		--menuItemWrapPadd: var(--sp3x);
		--menuItemPadd: var(--sp3x);
		--contactItemPadd: var(--sp3x);
		--nftButtonPadding: var(--sp1-5x) var(--sp1-75x);
		--nftButtonMarginButton: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		.footerTitleWrap {
			.menuTitle {
				flex: 0 0 23%;
				max-width: 23%;

				&:nth-child(3) {
					flex: 0 0 30%;
					max-width: 30%;
				}
			}
		}

		.footerMenuItemsWrap {
			.menuItem {
				flex: 0 0 23%;
				max-width: 23%;
				&:nth-child(3) {
					flex: 0 0 30%;
					max-width: 30%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--footeerBootomPadd: var(--sp3x);
		--TicketsPadd: var(--sp4x);
		--footerTopPadd: var(--sp4x);

		--starSize: var(--sp2-5x);
		--starPaddTop: var(--sp2-5x);
		--starPaddLeft: var(--sp2x);
		--menuItemWrapPadd: var(--sp3x);
		--menuItemPadd: var(--sp2x);
		--contactItemPadd: var(--sp2x);
		--nftButtonPadding: var(--sp1-5x) var(--sp1-75x);
		--nftButtonMarginButton: 0;

		.footerTitleWrap {
			.menuTitle {
				&:last-child {
					padding-left: 0;
				}
			}
		}

		.icon-star {
			&:after {
				top: -0.5px;
			}
		}

		.footerTop {
			&:first-of-type {
				border-bottom: 1px solid var(--textColor);

				.footerTitleWrap {
					.menuTitle {
						flex: 0 0 50%;
						max-width: 50%;
					}
				}

				.footerMenuItemsWrap {
					.menuItem {
						flex: 0 0 50%;
						max-width: 50%;
						&:last-child {
							.menu-item {
								display: flex;
								justify-content: flex-end;
							}
						}
						a {
							text-align: right;
						}
					}
				}
			}

			&:last-of-type {
				.footerTitleWrap {
					.menuTitle {
						&:first-child {
							flex: 0 0 57%;
							max-width: 57%;
						}
						&:last-child {
							flex: 0 0 40%;
							max-width: 40%;
						}
					}
				}

				.footerMenuItemsWrap {
					.menuItem {
						&:first-child {
							flex: 0 0 57%;
							max-width: 57%;
						}
						&:last-child {
							flex: 0 0 40%;
							max-width: 40%;
						}
					}
				}
			}
		}

		.footerBottom {
			> .FlexBox {
				margin-bottom: var(--sp2x);
			}
		}
	}
`

export default Footer
