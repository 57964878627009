import styled from 'styled-components'


const LanguageSwitcherStyle = styled.div`
    --langMarg:var(--spSize);
    
    span{
        margin-right:var(--langMarg);
        &:last-child{
            margin-right: 0;
            a{
                margin-right: 0;
            }
        }
        a{
            margin-right: var(--langMarg);
        }
    }
    

`

export default LanguageSwitcherStyle