import React, { memo as Memo } from 'react'

//* HOCs
import { withUIContext, withCartContext } from 'context'

//* Components
import { Translate, Icon } from 'components/common'

//* Styles
import CartStyle from './style'

const Cart = Memo(({ toggleCart, items_quantity, ...props }) => {
    return (
        <CartStyle>
            <div onClick={() => toggleCart()} timeout={1000} className={'pXS uppercase NotoSansRegular textCenter textAlignCenter'}>
                {props.winWidth >= 768 ?
                    <Translate val={'Cart'} />
                    :
                    <Icon className={'icon-cart'} />}
                <br />
                ({items_quantity})
            </div>
        </CartStyle>
    )
})

export default withUIContext(withCartContext(Cart,
    ['toggleCart', 'items_quantity']),
    ['screenSizes', 'winWidth'])