import React, { memo as Memo, useState, useRef, useEffect } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Config
import { arrayGen } from 'helpers'

//*component
import { Icon, Text, Image, AnimationWrapp, CustomLink } from 'components/common'

//* Styles
import SingleEventStyle from './style'
import AnimationWrappStyle from './styleAnimationWrapp'

const SingleEvent = Memo(({ selectedLang, winWidth, winHeight, ...props }) => {
    //! States
    const [secObj, setSecObj] = useState()
    const [secTopObj, setSecTopObj] = useState()
    const [startAnimation, setStartAnimation] = useState(false)

    //! Refs
    const titleRef = useRef()
    const contRef = useRef()
    const itemsRef = useRef()
    const dateRef = useRef()
    const iconNameRef = useRef()

    //* Text animation

    useEffect(() => {
        if (dateRef?.current && iconNameRef?.current && titleRef?.current && itemsRef?.current) {
            setSecTopObj(contRef.current)
            setSecObj([arrayGen([dateRef.current, iconNameRef.current.childNodes, titleRef.current, itemsRef.current.childNodes])])
        }
    }, [dateRef, iconNameRef, titleRef, itemsRef, selectedLang])

    const StartAnim = (x) => {
        x && !startAnimation ? setStartAnimation(true) : setStartAnimation(false)
    }

    return (
        <AnimationWrappStyle>
            <AnimationWrapp
                state={secObj}
                startPoint={'top+=0% bottom'}
                otherStartPoint={'top+=60% bottom'}
                sectionTop={secTopObj}
                sectionAnimationStart={(e) => StartAnim(e)}
                animationStagger={0.05}
                animationDuration={0.5}
            >
                <SingleEventStyle ref={contRef} rel='noopener noreferrer'>
                    <CustomLink url={props.url} className={`single-event FlexBox alignMiddle ${startAnimation ? 'active' : ''}`}>
                        <div className='info'>
                            <div className='FlexBoxContainer'>
                                <div className='left'>
                                    <Text tag={'h4'} className={'date h4 GHEAMariamBold uppercase singleTitle'} text={props.date} ref={dateRef} />
                                </div>
                                <div className='right'>
                                    <div className='overflowHidden'>
                                        <Text
                                            tag={'p'}
                                            className={'NotoSansRegular uppercase otherElementAnimation'}
                                            text={props.title}
                                            ref={titleRef}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='FlexBoxContainer'>
                                <div className='left categoryWrap'>
                                    <div className='category FlexBox alignMiddle overflowHidden' ref={iconNameRef}>
                                        <Icon className={`h6 otherElementAnimation ${props.category.icon ? props.category.icon : 'icon-star2'}`} />
                                        <Text tag={'p'} className={'pM NotoSansRegular uppercase otherElementAnimation'} text={props.category.name} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='event-options FlexBox alignMiddle overflowHidden' ref={itemsRef}>
                                        <div className='single-option FlexBoxColumn otherElementAnimation'>
                                            <Text tag={'p'} className={'single-option-title uppercase pXS GHEAMariamBold'} text={'duration'} />
                                            <div className='FlexBox'>
                                                <Text tag={'p'} className={'pM NotoSansRegular'} text={props.duration} />
                                                &nbsp;
                                                <Text tag={'p'} className={'pM NotoSansRegular'} text={'hour'} />
                                            </div>
                                        </div>
                                        <div className='single-option FlexBoxColumn otherElementAnimation'>
                                            <Text tag={'p'} className={'single-option-title uppercase pXS GHEAMariamBold'} text={'entrance'} />
                                            <Text tag={'p'} className={'pM NotoSansRegular'} text={props.entrance} />
                                        </div>
                                        {winWidth > 1279 ? (
                                            <span>
                                                <Icon className='icon-arrowRight' />
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Image src={props.image} />
                    </CustomLink>
                </SingleEventStyle>
            </AnimationWrapp>
        </AnimationWrappStyle>
    )
})

export default withLanguageContext(withUIContext(SingleEvent,
    ['winWidth', 'winHeight']),
    ['selectedLang'])
