import React, { memo as Memo } from 'react'

//* Components
import { Icon } from 'components/common'

//style
import ShareStyle from './style'

const SocIcon = Memo((props) => {
    return (
        <ShareStyle className='socIconWrapp FlexBox alignMiddle'>
            <a href={props.items?.facebook} className='socIconItem FlexBox alignMiddle textColor' target='_blank' rel='noopener noreferrer' aria-label="Sergey Parajanov museum facebook Page">
                <Icon className={'icon-facebook'} />
            </a>
            <a href={props.items?.instagram} className='socIconItem FlexBox alignMiddle textColor' target='_blank' rel='noopener noreferrer' aria-label="Sergey Parajanov museum instagram Page">
                <Icon className={'icon-instagram'} />
            </a>
        </ShareStyle>
    )
})

export default SocIcon
