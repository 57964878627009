import App from 'next/app'
import React from 'react'

//* Providers
import { UIProvider, DataProvider, LanguageProvider, CartProvider } from 'context'

//* Components
import { SoundOnOff, Loader, AppHeader, AppFooter, CartSlide } from 'components/common'

//* Helpers
import { getData, getPageParams, config } from 'helpers'

//* Icon
import 'styles/icons/style.css'
import 'styles/select.css'

//* Fonts
import 'styles/font.css'

class MyApp extends App {
	static async getInitialProps({ Component, ctx, router, ...props }) {
		//! Checking If First Request or Not
		if (!process.browser) {
			//! Default States
			const newState = {
				pages: {},
				global: {},
				news: {},
				singleItem: {},
			}

			//! Getting Page Data
			const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true })

			return {
				pageProps: {
					...data,
					browser: false,
					isSafari: /^((?!chrome|android).)*safari/i?.test(ctx.req?.headers['user-agent']),
				},
			}
		} else {
			return {
				pageProps: {
					pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
					browser: true,
					isSafari: /^((?!chrome|android).)*safari/i?.test(navigator.userAgent),
				},
			}
		}
	}

	render() {
		const { Component, pageProps, router } = this.props

		return (
			<LanguageProvider>
				<UIProvider>
					<DataProvider newData={pageProps}>
						<SoundOnOff />
						<Loader />
						<CartProvider>
							<CartSlide />
							{router.pathname !== '/404' && <AppHeader isSafari={pageProps.isSafari} />}
							<section className='minHeight'>
								<Component {...pageProps} config={config.routes} selectedLang={router.locale} />
							</section>
							{router.pathname !== '/404' && <AppFooter />}
						</CartProvider>
					</DataProvider>
				</UIProvider>
			</LanguageProvider>
		)
	}
}

export default MyApp
