import { forwardRef } from 'react'
import { withLanguageContext } from 'context'
import Link from 'next/link'

const CustomLink = forwardRef(({ selectedLang, options, ...props }, ref) => {
    return (
        <Link
            scroll={props.scroll}
            shallow={props.shallow}
            href={props.url}
        >
            <a ref={ref} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} onMouseEnter={props.onMouseEnter} onClick={props.onClick} className={`${props.className} ${options?.disabled ? 'disabled' : ''}`} id={props.id && props.id} {...options}>
                {props.content || props.children}
            </a>
        </Link>
    )
})

export default withLanguageContext(CustomLink, ['selectedLang'])
