import React, { memo as Memo } from 'react';
import { CustomLink } from 'components/common'

const Nav = Memo(props => {
    return (
            <CustomLink className={"Link"} url={props.path} content={props.children} nav={true} data={props.data}/>
    )
})

export default Nav
