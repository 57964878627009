import styled from 'styled-components'


const CartSlideStyle = styled.div.attrs((props) => ({}))`
    color: var(--backColor);
    z-index: 105;
    position: relative;
    transition: var(--trTime);
    background-color:var(--textColor);

    .cart-block {
        height:0%;
        &.active{
            height:auto;
        }
        .heading {
            padding:var(--sp7x) var(--sp8x);
            border-bottom: 1px solid var(--backColor);
            position: relative;

            .item {
                width:15%;
            }

            .qty {
                width: 20%;
            }

            .size {
                width: 40%;
            }

            .price {
                width: 25%;
            }

            .brg {
                position: absolute;
                top:50%;
                right: var(--sp8x);
                padding: 0;
                transform: translateY(-50%);

                .Burger {
                    span {
                        background-color: var(--backColor);
                    }
                }
            }
        }

        .main-cart {
            .single-product {
                padding:var(--sp9x) var(--sp8x);
                border-bottom: 1px solid var(--backColor);
                position: relative;

                .item {
                    width:15%;
                }

                .qty {
                    width: 20%;
                    cursor: pointer;
                    user-select: none;

                    .quantity {
                        margin: 0 var(--sp5x);
                        cursor: default;
                    }
                }

                .size {
                    width: 40%;
                }

                .price {
                    width: 25%;
                }

                .remove {
                    position: absolute;
                    top:50%;
                    transform: translateY(-50%);
                    right: var(--sp8x);
                    cursor: pointer;
                }
            }
        }

        .total {
            .row {
                padding:var(--sp6x) var(--sp8x);
                border-bottom: 1px solid var(--backColor);

                .title { 
                    width: 75%;
                }

                .value {
                    width: 25%;
                }
            }
        }

        .ship-text {
            padding:var(--sp5x) var(--sp8x);
        }

        .to-checkout {
            color: var(--textColor);
            background-color: var(--backColor);
            padding:var(--sp7x) 0;
            border-bottom: 1px solid var(--textColor);
            &.disabled{
                opacity: 0.8;
            }
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.DesktopSizeM}){
        .cart-block {
            .heading {
                padding:var(--sp6x) var(--sp8x);
            }

            .main-cart {
                .single-product {
                    padding:var(--sp8x);
                }
            }

            .total {
                .row {
                    padding:var(--sp6x) var(--sp8x);
                }
            }

            .ship-text {
                padding:var(--sp4x) var(--sp8x);
            }

            .to-checkout {
                padding:var(--sp6x) 0;
            }
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.DesktopSizeS}){
        .cart-block {
            .heading {
                padding:var(--sp6x) var(--sp5x);

                .brg {
                    right: var(--sp5x);
                }
            }

            .main-cart {
                .single-product {
                    padding:var(--sp8x) var(--sp5x);

                    .remove {
                        right: var(--sp5x);
                    }
                }
            }

            .total {
                .row {
                    padding:var(--sp5x);
                }
            }

            .ship-text {
                padding:var(--sp4x) var(--sp5x);
            }

            .to-checkout {
                padding:var(--sp5x) 0;
            }
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.DesktopSizeXS}){
        .cart-block {
            .heading {
                padding:var(--sp5x);

                .brg {
                    right: var(--sp5x);
                }
            }

            .main-cart {
                .single-product {
                    padding:var(--sp6x) var(--sp5x);

                    .remove {
                        right: var(--sp5x);
                    }
                }
            }

            .total {
                .row {
                    padding:var(--sp4x) var(--sp5x);
                }
            }

            .ship-text {
                padding:var(--sp4x) var(--sp5x);
            }

            .to-checkout {
                padding:var(--sp5x) 0;
            }
        }
    }
    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.LaptopSize}){
        .cart-block {
            .heading {
                padding:var(--sp4x) var(--sp5x);

                .brg {
                    right: var(--sp5x);
                }
            }

            .main-cart {
                .single-product {
                    padding:var(--sp5x) var(--sp5x);

                    .remove {
                        right: var(--sp5x);
                    }
                }
            }

            .total {
                .row {
                    padding:var(--sp3x) var(--sp5x);
                }
            }

            .ship-text {
                padding:var(--sp3x) var(--sp5x);
            }

            .to-checkout {
                padding:var(--sp5x) 0;
            }
        }
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
    props.theme.mediaQuery.TabletSize}){
        .cart-block {
            .heading {
                padding:var(--sp4x) var(--sp5x);

                .brg {
                    right: var(--sp5x);
                }

                .item {
                    width:30%;
                }

                .qty {
                    width: 25%;
                }

                .size {
                    width: 20%;
                }

                .price {
                    width: 25%;
                }
            }

            .main-cart {
                .single-product {
                    padding:var(--sp5x) var(--sp5x);

                    .remove {
                        right: var(--sp5x);
                    }

                    .item {
                        width:30%;
                    }

                    .qty {
                        width: 25%;

                        .quantity {
                            margin: 0 var(--sp4x);
                        }
                    }

                    .size {
                        width: 20%;
                    }

                    .price {
                        width: 25%;
                    }
                }
            }

            .total {
                .row {
                    padding:var(--sp3x) var(--sp5x);
                }
            }

            .ship-text {
                padding:var(--sp3x) var(--sp5x);
            }

            .to-checkout {
                padding:var(--sp5x) 0;
            }
        }
    }
    @media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}){
        .cart-block {
            .heading {
                padding:var(--sp10x) var(--sp2x) var(--sp2x);

                &:before {
                    content:'';
                    position: absolute;
                    top:var(--sp8x);
                    left:0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--backColor);
                }
                
                .brg {
                    top:26px;
                    right: var(--sp2x);
                    transform: translateY(0);
                }

                .item {
                    width:30%;
                }

                .qty {
                    width: 28%;
                }

                .size {
                    width: 20%;
                }

                .price {
                    width: 22%;
                    text-align:right;
                }
            }

            .main-cart {
                .single-product {
                    padding:var(--sp3x) var(--sp2x) var(--sp8x);

                    .remove {
                        right: var(--sp2x);
                    }

                    .item {
                        width:30%;
                    }

                    .qty {
                        width: 28%;

                        .quantity {
                            margin: 0 var(--sp3-5x);
                        }
                    }

                    .size {
                        width: 20%;
                    }

                    .price {
                        width: 22%;
                        text-align:right;
                    }

                    .remove {
                        top: unset;
                        bottom: var(--sp3x);
                        line-height: 1;
                        transform: translateY(0);
                        line-height: 1;
                        font-size: var(--sp2x);
                    }
                }
            }

            .total {
                .row {
                    padding:var(--sp3x) var(--sp2x);

                    .value {
                        text-align:right;
                    }
                }
            }

            .ship-text {
                padding:var(--sp3x) var(--sp2x);
            }

            .to-checkout {
                padding:var(--sp3x) 0;
            }
        }
    }
`

export default CartSlideStyle